import { AssetValue, GetCurrentValidatorsResponse, NodeId } from 'juneojs'

import { selectNetworkProvider } from './common/networks'

// example usage in terminal
// npx ts-node ./scripts/run.ts
async function trackValidatorNodeID(
  network: string | undefined,
  nodeID: string | undefined,
) {
  const provider = selectNetworkProvider(network)
  if (nodeID === undefined) {
    console.log('missing argument to execute script please provide a nodeID')
    return
  }
  try {
    new NodeId(nodeID)
  } catch {
    console.log(
      `provided nodeID is not valid: "${nodeID}" is it properly formatted?`,
    )
    throw new Error(
      `provided nodeID is not valid: "${nodeID}" is it properly formatted?`,
    )
  }
  const validators: GetCurrentValidatorsResponse =
    await provider.platformApi.getCurrentValidators(provider.mcn.primary.id, [
      nodeID,
    ])
  if (validators.validators.length < 1) {
    console.log(`could not find validator with nodeID: ${nodeID}`)
    throw new Error(`could not find validator with nodeID: ${nodeID}`)
  } else {
    console.log('found validator: ')
    const validator = validators.validators[0]
    const uptime = `${Math.round(validator.uptime)}%`
    const startTime = new Date(Number(validator.startTime) * 1000)
    const endTime = new Date(Number(validator.endTime) * 1000)
    const elapsedTimeValue = Math.floor(
      (new Date().getTime() - startTime.getTime()) / 1000 / 86400,
    )
    const elapsedTime = `${elapsedTimeValue} days`
    const remainingTime = `${
      Math.floor((endTime.getTime() - startTime.getTime()) / 1000 / 86400) -
      elapsedTimeValue
    } days`
    const decimals = provider.platformChain.asset.decimals
    const stake = `${new AssetValue(
      validator.stakeAmount,
      decimals,
    ).getReadableValue()} ${provider.platformChain.asset.name}`
    const potentialReward = `${new AssetValue(
      validator.potentialReward,
      decimals,
    ).getReadableValue()} ${provider.platformChain.asset.name}`
    console.log({
      nodeID: validator.nodeID,
      connected: validator.connected,
      uptime,
      startTime,
      endTime,
      elapsedTime,
      remainingTime,
      stake,
      potentialReward,
    })

    const validatorInfo = {
      nodeID: validator.nodeID,
      connected: validator.connected,
      uptime,
      startTime,
      endTime,
      elapsedTime,
      remainingTime,
      stake,
      potentialReward,
    }
    return validatorInfo
  }
}

export default trackValidatorNodeID
