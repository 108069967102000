import "./ValidateTransaction.scss";

import { AssetValue, MainNetwork, MCNProvider, SocotraNetwork } from "juneojs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCheck } from "react-icons/fa";

import { GrClose } from "../../../assets/svg/svgIcons";
import {
  Button,
  Card,
  StyledInputSearch,
  StyledSwitch,
} from "../../../mcn-react-ui";
import DynamicTable from "../../../mcn-react-ui/DynamicTable/DynamicTable";
import FormatTransaction from "../../../mcn-react-ui/Utils/Format/FormatTransaction/FormatTransaction";
import useNetworkStore from "../../../stores/NetworkStore";
import appFetch from "../../../utils/services/Fetch/appFetch";
import { ReceiverAddress, Vesting } from "../SendToken";

export const formatAmount = (
  number: bigint,
  blockchainId: string,
  networkId: string,
) => {
  let provider = new MCNProvider(SocotraNetwork);
  if (Number(networkId) === MainNetwork.id) {
    provider = new MCNProvider(MainNetwork);
  }
  let decimals = 9;

  if (provider.juneChain.id === blockchainId) {
    decimals = 18;
  }

  return new AssetValue(number, decimals).getReadableValue();
};

const ValidateTransaction = () => {
  const [transactions, setTransactions] = useState<ReceiverAddress[]>([]);
  const [filteredTransactions, setFilteredTransactions] =
    useState<ReceiverAddress[]>(transactions);
  const [search, setSearch] = useState<string>("");
  const [showSended, setShowSended] = useState<boolean>(false);
  const { provider, network } = useNetworkStore();

  useEffect(() => {
    try {
      const fetchAddresses = async () => {
        const response = await appFetch(
          `/api/transactionsInternal/getTransactionsInternal?networkId=${provider.mcn.id}`,
        );
        setTransactions(response);
      };

      fetchAddresses();
    } catch (error) {
      console.error("Error:", error);
    }
  }, [provider]);

  const handleValidateVesting = async (vestingId: string) => {
    try {
      const response = await appFetch(
        "/api/transactionsInternal/validateTransactionInternal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ vestingId }),
        },
      );
      if (response) {
        const updatedTransactions = transactions.map((transaction) => {
          const updatedVestings = transaction.vestings.map((vesting) => {
            if (vesting.vestingId === vestingId) {
              return { ...vesting, validated: true };
            }
            return vesting;
          });
          return { ...transaction, vestings: updatedVestings };
        });
        setTransactions(updatedTransactions);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handledeleteVesting = async (vestingId: string) => {
    try {
      const response = await appFetch(
        "/api/transactionsInternal/deleteTransactionInternal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ vestingId }),
        },
      );
      if (response) {
        toast.success("Vesting deleted");
        // remove from state
        const updatedTransactions = transactions.map((transaction) => {
          const updatedVestings = transaction.vestings.filter(
            (vesting) => vesting.vestingId !== vestingId,
          );
          return { ...transaction, vestings: updatedVestings };
        });
        setTransactions(updatedTransactions);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const collumns = [
    {
      title: "Amount",
      dataIndex: "amount",
      render: (value: bigint, transaction: ReceiverAddress) => {
        return (
          <div>
            {formatAmount(
              value,
              transaction.blockchainId,
              transaction.networkId,
            )}{" "}
            JUNE
          </div>
        );
      },
    },

    {
      title: "Validated",
      dataIndex: "validated",
      render: (value: boolean) => {
        return <span className={value ? "green" : "red"}></span>;
      },
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      render: (value: string | null, transaction: ReceiverAddress) => {
        return value ? (
          <FormatTransaction
            transactionId={value}
            explorer={`https://${network.id}.mcnscan.io/chain/${transaction.blockchainId}/`}
            start={5}
            end={5}
          />
        ) : (
          "Not sent"
        );
      },
    },
    {
      title: "Metadata",
      dataIndex: "transactionMetadata",
      render: (value: string) => {
        return <span>{value}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "validated",
      render: (value: boolean, vesting: Vesting) => {
        return (
          <>
            <Button
              onClick={() => handleValidateVesting(vesting.vestingId)}
              disabled={value}
              style="square"
            >
              <FaCheck />
            </Button>
            <Button
              onClick={() => handledeleteVesting(vesting.vestingId)}
              style="square"
              disabled={vesting.sended}
            >
              <GrClose />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!transactions) return;
    const filtered = transactions
      .map((transaction) => ({
        ...transaction,
        vestings: transaction.vestings.filter(
          (vesting) => showSended || !vesting.sended,
        ),
      }))
      .filter((transaction) => {
        const searchMatch =
          transaction.address.toLowerCase().includes(search.toLowerCase()) ||
          transaction.metadata.toLowerCase().includes(search.toLowerCase());
        return searchMatch && transaction.vestings.length > 0;
      });

    setFilteredTransactions(filtered);
  }, [search, transactions, showSended]);

  return (
    <div className="validateTransaction">
      <div className="header">
        <StyledInputSearch
          value={search}
          setValue={setSearch}
          label="Search"
          placeholder="0x"
          className="search"
        />
        <StyledSwitch
          checked={showSended}
          onChange={() => setShowSended(!showSended)}
          label="Show Sended"
        />
      </div>
      {filteredTransactions &&
        filteredTransactions.map((transaction) => (
          <Card key={transaction.address}>
            <h3>{transaction.address}</h3>
            <p>Stakeholder : {transaction.metadata}</p>
            <p>
              {" "}
              Total amount :
              {formatAmount(
                transaction.totalAmount,
                transaction.blockchainId,
                transaction.networkId,
              )}{" "}
              JUNE
            </p>
            <DynamicTable
              data={transaction.vestings}
              columns={collumns}
              mobile
            />
          </Card>
        ))}
      {filteredTransactions.length === 0 && <Card>No transactions found</Card>}
    </div>
  );
};

export default ValidateTransaction;
