/// <reference types="vite-plugin-svgr/client" />

import "./App.scss";

import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import navItems from "./components/Navbar/NavItems";
import { Navbar } from "./mcn-react-ui";
import Home from "./pages/Home/Home";
import JuneoValidators from "./pages/JuneoValidators/JuneoValidators";
import LatestValidators from "./pages/LatestValidators/LatestValidators";
import Login from "./pages/Login/Login";
import NetworkData from "./pages/NetworkData/NetworkData";
import SendToken from "./pages/SendToken/SendToken";
import TokenDistribution from "./pages/TokenDistribution/TokenDistribution";
import TrackValidator from "./pages/TrackValidator/TrackValidator";
import PrivateRoutes from "./utils/PrivateRoutes/PrivateRoutes";

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Navbar navItems={navItems} />
        </header>
        <section className="mainSection">
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                path="/tokenDistribution"
                element={<TokenDistribution />}
              />
              <Route path="/sendToken" element={<SendToken />} />
            </Route>
            <>
              <Route path="/" element={<Home />} />
              <Route path="/latestValidators" element={<LatestValidators />} />
              <Route path="/trackValidator" element={<TrackValidator />} />
              <Route path="/networkData" element={<NetworkData />} />
              <Route path="/juneoValidators" element={<JuneoValidators />} />
              <Route path="/login" element={<Login />} />
            </>
          </Routes>
        </section>
      </Router>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default App;
