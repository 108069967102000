import "./FailedTransaction.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { GrClose } from "../../../assets/svg/svgIcons";
import {
  Button,
  Card,
  FormatDate,
  StyledInputSearch,
} from "../../../mcn-react-ui";
import DynamicTable from "../../../mcn-react-ui/DynamicTable/DynamicTable";
import FormatTransaction from "../../../mcn-react-ui/Utils/Format/FormatTransaction/FormatTransaction";
import useNetworkStore from "../../../stores/NetworkStore";
import appFetch from "../../../utils/services/Fetch/appFetch";
import { ReceiverAddress, Vesting } from "../TokenDistribution";
import { formatAmount } from "../ValidateTransaction/ValidateTransaction";

const FailedTransaction = () => {
  const [transactions, setTransactions] = useState<ReceiverAddress[]>([]);
  const [filteredTransactions, setFilteredTransactions] =
    useState<ReceiverAddress[]>(transactions);
  const [search, setSearch] = useState<string>("");
  const { provider, network } = useNetworkStore();

  useEffect(() => {
    try {
      const fetchAddresses = async () => {
        const response = await appFetch(
          `/api/transactions/getFailedTransactions?networkId=${provider.mcn.id}`,
        );
        setTransactions(response);
      };

      fetchAddresses();
    } catch (error) {
      console.error("Error:", error);
    }
  }, [provider]);

  const handledeleteVesting = async (vestingId: string) => {
    try {
      const response = await appFetch("/api/transactions/deleteTransaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ vestingId }),
      });
      if (response) {
        toast.success("Vesting deleted");
        // remove from state
        const updatedTransactions = transactions.map((transaction) => {
          const updatedVestings = transaction.vestings.filter(
            (vesting) => vesting.vestingId !== vestingId,
          );
          return { ...transaction, vestings: updatedVestings };
        });
        setTransactions(updatedTransactions);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const collumns = [
    {
      title: "Amount",
      dataIndex: "amount",
      render: (value: bigint, transaction: ReceiverAddress) => {
        return (
          <div>
            {formatAmount(
              value,
              transaction.blockchainId,
              transaction.networkId,
            )}{" "}
            JUNE
          </div>
        );
      },
    },
    {
      title: "Lock Time",
      dataIndex: "releaseTime",
      render: (value: string) => {
        return value === "0" ? (
          "Today"
        ) : (
          <FormatDate timestamp={new Date(parseInt(value)).getTime()} />
        );
      },
    },
    {
      title: "Validated",
      dataIndex: "validated",
      render: (value: boolean) => {
        return <span className={value ? "green" : "red"}></span>;
      },
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      render: (value: string | null, transaction: ReceiverAddress) => {
        return value ? (
          <FormatTransaction
            transactionId={value}
            explorer={`https://${network.id}.mcnscan.io/chain/${transaction.blockchainId}/`}
            start={5}
            end={5}
          />
        ) : (
          "Not sent"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "transactionStatus",
      render: (value: string | null) => {
        return value ? value : "Not sent";
      },
    },
    {
      title: "Action",
      dataIndex: "transactionId",
      render: (value: boolean, vesting: Vesting) => {
        return (
          <>
            <Button
              onClick={() => handledeleteVesting(vesting.vestingId)}
              style="square"
              disabled={value !== null}
            >
              <GrClose />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!transactions) return;
    const filtered = transactions.filter((transaction) => {
      const searchMatch =
        transaction.address.toLowerCase().includes(search.toLowerCase()) ||
        transaction.metadata.toLowerCase().includes(search.toLowerCase()) ||
        transaction.vestingType.toLowerCase().includes(search.toLowerCase());
      return searchMatch && transaction.vestings.length > 0;
    });

    setFilteredTransactions(filtered);
  }, [search, transactions]);

  return (
    <div className="validateTransaction">
      <div className="header">
        <StyledInputSearch
          value={search}
          setValue={setSearch}
          label="Search"
          placeholder="0x"
          className="search"
        />
      </div>
      {filteredTransactions &&
        filteredTransactions.map((transaction) => (
          <Card key={transaction.address}>
            <h3>{transaction.address}</h3>
            <p>type : {transaction.vestingType}</p>
            <p>metadata : {transaction.metadata}</p>
            <p>
              {" "}
              Total amount :
              {formatAmount(
                transaction.totalAmount,
                transaction.blockchainId,
                transaction.networkId,
              )}{" "}
              JUNE
            </p>
            <DynamicTable
              data={transaction.vestings}
              columns={collumns}
              mobile
            />
          </Card>
        ))}
      {filteredTransactions.length === 0 && <Card>No transactions found</Card>}
    </div>
  );
};

export default FailedTransaction;
