import { AssetValue } from 'juneojs'

import { ValidatorInfo } from '../components/ValidatorCard/ValidatorCard'
import { selectNetworkProvider } from './common/networks'

// example usage in terminal
// npx ts-node ./scripts/run.ts
const DEFAULT_AMOUNT = 10

async function fetchLatestValidators(
  network: string | undefined,
  amount: string | undefined | number,
) {
  const provider = selectNetworkProvider(network)
  if (amount === undefined) {
    amount = DEFAULT_AMOUNT
  }
  if (typeof amount === 'string') {
    amount = Number(amount)
    if (Number.isNaN(amount)) {
      console.log(
        `provided amount is not valid: "${amount}" is it properly formatted?`,
      )
      throw new Error(
        `provided amount is not valid: "${amount}" is it properly formatted?`,
      )
    }
  }
  const validators = await provider.platformApi.getCurrentValidators()
  let logged = 0
  const newValidators: ValidatorInfo[] = []
  for (const validator of validators.validators.sort((a, b) => {
    return Number(b.startTime) - Number(a.startTime)
  })) {
    const uptime = `${Math.round(validator.uptime)}%`
    const startTime = new Date(Number(validator.startTime) * 1000)
    const endTime = new Date(Number(validator.endTime) * 1000)
    const elapsedTimeValue = Math.floor(
      (new Date().getTime() - startTime.getTime()) / 1000 / 86400,
    )
    const elapsedTime = `${elapsedTimeValue} days`
    const remainingTime = `${
      Math.floor((endTime.getTime() - startTime.getTime()) / 1000 / 86400) -
      elapsedTimeValue
    } days`
    const decimals = provider.platformChain.asset.decimals
    const stake = `${new AssetValue(
      validator.stakeAmount,
      decimals,
    ).getReadableValue()} ${provider.platformChain.asset.name}`
    const potentialReward = `${new AssetValue(
      validator.potentialReward,
      decimals,
    ).getReadableValue()} ${provider.platformChain.asset.name}`
    const data: ValidatorInfo = {
      nodeID: validator.nodeID,
      connected: validator.connected,
      uptime,
      startTime,
      endTime,
      elapsedTime,
      remainingTime,
      stake,
      potentialReward,
    }
    newValidators.push(data)
    console.log(data)
    if (++logged === amount) {
      break
    }
  }
  return newValidators
}

export default fetchLatestValidators
