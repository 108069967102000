interface ErrorComponentProps {
  error: string
}

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  return (
    <div className="dataDisplay">
      <div className="dataField">
        <label>error: </label>
        <span>{error}</span>
      </div>
    </div>
  )
}

export default ErrorComponent
