import React from "react";

interface FormatDateProps {
  timestamp: number;
  className?: string;
  full?: boolean;
  inline?: boolean;
}

const FormatDate: React.FC<FormatDateProps> = ({
  className,
  timestamp,
  full = false,
  inline = true,
}) => {
  const formatDate = (): JSX.Element => {
    const date = new Date(
      timestamp.toString().length === 10 ? timestamp * 1000 : timestamp,
    );
    const options: Intl.DateTimeFormatOptions = full
      ? {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }
      : { year: "numeric", month: "numeric", day: "numeric" };

    const dateString = date.toLocaleString(undefined, options);

    if (full && !inline) {
      const [datePart, timePart] = dateString.split(", ");
      return (
        <>
          <span>{datePart}</span>
          <br />
          <span>{timePart}</span>
        </>
      );
    } else {
      return <>{dateString}</>;
    }
  };

  return (
    <span className={`formatDate ${className ?? ""}`}>{formatDate()}</span>
  );
};

export default FormatDate;
