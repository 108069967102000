import {
  GenesisNetwork,
  MainNetwork,
  MCNProvider,
  SocotraNetwork,
} from 'juneojs'

export const GENESIS_NETWORK = 'genesis'
export const SOCOTRA_NETWORK = 'socotra'
export const MAIN_NETWORK = 'mainnet'

export const NETWORKS: string[] = [
  SOCOTRA_NETWORK,
  GENESIS_NETWORK,
  MAIN_NETWORK,
]

export function selectNetworkProvider(
  network: string | undefined,
): MCNProvider {
  if (network === undefined) {
    console.log(
      `provided network is not valid: "${network}" use: [${NETWORKS}]`,
    )
    throw new Error('network not provided')
  }
  let provider: MCNProvider | undefined = undefined
  switch (network.toLocaleLowerCase() as string) {
    case GENESIS_NETWORK: {
      provider = new MCNProvider(GenesisNetwork)
      break
    }
    case SOCOTRA_NETWORK: {
      provider = new MCNProvider(SocotraNetwork)
      break
    }
    case MAIN_NETWORK: {
      provider = new MCNProvider(MainNetwork)
      break
    }
  }
  if (typeof provider === 'undefined') {
    throw new Error('unexpected data missing mcn please update script')
  }
  console.log(`Selected ${provider.mcn.name} network.`)
  return provider
}
