import "./SendToken.scss";

import { useEffect, useState } from "react";

import {
  ButtonSwitcher,
  Container,
  ContainerContent,
} from "../../mcn-react-ui";
import HeaderButtons from "../../mcn-react-ui/HeaderButtons/HeaderButtons";
import useNetworkStore from "../../stores/NetworkStore";
import CreateTransaction from "./CreateTransaction/CreateTransaction";
import FailedTransaction from "./FailedTransaction/FailedTransaction";
import SendTransaction from "./SendTransaction/SendTransaction";
import ValidateTransaction from "./ValidateTransaction/ValidateTransaction";

export type Vesting = {
  amount: string;
  releaseTime: Date | undefined;
  validated: boolean;
  vestingId: string;
  transactionId?: string | undefined;
  sended?: boolean;
};

export type ReceiverAddress = {
  address: string;
  vestingType: "airdrop" | "presale";
  metadata: string;
  totalAmount: bigint;
  vestings: Vesting[];
  blockchainId: string;
  networkId: string;
};

const SendToken = () => {
  const [pageType, setPageType] = useState("Create transaction");
  const { network, setNetwork } = useNetworkStore();

  const buttonList = [
    { text: "Create transaction" },
    { text: "Validate transaction" },
    { text: "Send transaction" },
    { text: "Failed transaction" },
  ];
  const networkList = [
    { id: "Socotra", name: "Socotra" },
    { id: "Mainnet", name: "Mainnet" },
  ];

  useEffect(() => {
    // if network is genesis we put socotra
    if (network.id === "Genesis") {
      setNetwork(networkList[0]);
    }
  }, [network]);

  return (
    <Container>
      <HeaderButtons
        activeButton={pageType}
        setActiveButton={setPageType}
        buttons={buttonList}
      />
      <ContainerContent className="sendTokenPage">
        <ButtonSwitcher
          datas={networkList}
          value={network}
          setValue={setNetwork}
        />

        {pageType === "Validate transaction" && <ValidateTransaction />}
        {pageType === "Create transaction" && <CreateTransaction />}
        {pageType === "Send transaction" && <SendTransaction />}
        {pageType === "Failed transaction" && <FailedTransaction />}
      </ContainerContent>
    </Container>
  );
};

export default SendToken;
