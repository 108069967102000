import { useEffect, useState } from "react";

import { Button, Card, StyledInputSearch } from "../../../mcn-react-ui";
import DynamicTable from "../../../mcn-react-ui/DynamicTable/DynamicTable";
import FormatTransaction from "../../../mcn-react-ui/Utils/Format/FormatTransaction/FormatTransaction";
import useLoadingStore from "../../../stores/LoadingStore";
import useNetworkStore from "../../../stores/NetworkStore";
import appFetch from "../../../utils/services/Fetch/appFetch";
import { ReceiverAddress, Vesting } from "../SendToken";
import { formatAmount } from "../ValidateTransaction/ValidateTransaction";

const SendTransaction = () => {
  const [transactions, setTransactions] = useState<ReceiverAddress[]>([]);
  const [filteredTransactions, setFilteredTransactions] =
    useState<ReceiverAddress[]>(transactions);
  const [search, setSearch] = useState<string>("");
  const { setLoading, loadingStates } = useLoadingStore();
  const { provider, network } = useNetworkStore();

  useEffect(() => {
    try {
      const fetchAddresses = async () => {
        const response = await appFetch(
          `/api/transactionsInternal/getValidTransactionsInternal?networkId=${provider.mcn.id}`,
        );
        setTransactions(response);
      };

      fetchAddresses();
    } catch (error) {
      console.error("Error:", error);
    }
  }, [provider]);

  const handleSendVesting = async (vestingId: string) => {
    setLoading(`sendTransaction-${vestingId}`, true);
    setLoading(`sendTransaction`, true);
    try {
      const response = await appFetch(
        "/api/transactionsInternal/sendTransactionInternal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ transactionId: vestingId }),
        },
      );
      if (response) {
        // set the transacrtion id to the tx with response.txId
        const updatedTransactions = transactions.map((transaction) => {
          const updatedVestings = transaction.vestings.map((vesting) => {
            if (vesting.vestingId === vestingId) {
              return { ...vesting, transactionId: response.txId };
            }
            return vesting;
          });
          return { ...transaction, vestings: updatedVestings };
        });

        setTransactions(updatedTransactions);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(`sendTransaction-${vestingId}`, false);
    setLoading(`sendTransaction`, false);
  };

  const collumns = [
    {
      title: "Amount",
      dataIndex: "amount",
      render: (value: bigint, transaction: ReceiverAddress) => {
        return (
          <div>
            {formatAmount(
              value,
              transaction.blockchainId,
              transaction.networkId,
            )}{" "}
            JUNE
          </div>
        );
      },
    },

    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      render: (value: string | null, transaction: ReceiverAddress) => {
        return value ? (
          <FormatTransaction
            transactionId={value}
            explorer={`https://${network.id}.mcnscan.io/chain/${transaction.blockchainId}/`}
            start={5}
            end={5}
          />
        ) : (
          "Not sent"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "vestingId",
      render: (vestingId: string, transaction: Vesting) => {
        return (
          <Button
            onClick={() => handleSendVesting(vestingId)}
            style="white"
            isLoading={loadingStates[`sendTransaction-${vestingId}`]}
            disabled={
              transaction.transactionId
                ? true
                : loadingStates[`sendTransaction`]
            }
          >
            Send
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (search === "") {
      setFilteredTransactions(transactions);
    } else {
      const filtered = transactions.filter(
        (transaction) =>
          transaction.address.toLowerCase().includes(search.toLowerCase()) ||
          transaction.metadata.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteredTransactions(filtered);
    }
  }, [search, transactions]);

  return (
    <>
      <StyledInputSearch
        value={search}
        setValue={setSearch}
        label="Search"
        placeholder="0x"
        className="search"
      />
      {filteredTransactions &&
        filteredTransactions.map((transaction) => (
          <Card key={transaction.address}>
            <h3>{transaction.address}</h3>
            <p>type : {transaction.vestingType}</p>
            <p>metadata : {transaction.metadata}</p>
            <p>
              Total amount :
              {formatAmount(
                transaction.totalAmount,
                transaction.blockchainId,
                transaction.networkId,
              )}{" "}
              JUNE
            </p>
            <DynamicTable
              data={transaction.vestings}
              columns={collumns}
              mobile
            />
          </Card>
        ))}
      {filteredTransactions && filteredTransactions.length === 0 && (
        <Card>No transactions found</Card>
      )}
    </>
  );
};

export default SendTransaction;
