import "./ButtonSwitcher.scss";

import React, { useCallback } from "react";

export type ButtonSwitcherValue = {
  id: string;
  name: string;
};

interface ButtonSwitcherProps<T> {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  datas: T[];
  className?: string;
  localStorageKey?: string;
  style?: React.CSSProperties;
  rows?: number;
  collumns?: number;
  width?: string;
}

const ButtonSwitcher = <T extends ButtonSwitcherValue>({
  value,
  setValue,
  datas,
  className,
  localStorageKey,
  style,
  rows = 1,
  collumns = datas.length,
  width = "fit-content",
}: ButtonSwitcherProps<T>) => {
  const handleClick = useCallback(
    (item: T) => {
      setValue(item);
      if (localStorageKey && "id" in item) {
        localStorage.setItem(localStorageKey, item.id);
      }
    },
    [setValue, localStorageKey],
  );

  return (
    <div
      className={`buttonSwitcher ${className}`}
      style={{
        gridTemplateColumns: `repeat(${collumns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
        width: width,
      }}
    >
      {datas.map((item) => (
        <button
          key={item.id}
          onClick={() => handleClick(item)}
          className={`buttonSetValue ${value.id === item.id ? "selected" : ""}`}
          style={{ ...style }}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
};

export default ButtonSwitcher;
