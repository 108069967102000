import { AssetValue, ValidatorData } from 'juneojs'

import { ValidatorInfo } from '../components/ValidatorCard/ValidatorCard'
import { selectNetworkProvider } from './common/networks'
import { getNetworkValidators } from './common/validators'

// example usage in terminal
// npx ts-node ./scripts/run.ts
async function fetchJuneoValidators(network: string | undefined) {
  const provider = selectNetworkProvider(network)
  const validators = getNetworkValidators(provider)
  const found: Map<string, ValidatorData> = new Map()
  const disconnected: Map<string, ValidatorData> = new Map()
  let currentJuneoStake = BigInt(0)
  const currentValidators = await provider.platformApi.getCurrentValidators()
  const juneoValidators: ValidatorInfo[] = []
  for (const validator of currentValidators.validators) {
    if (!validators.includes(validator.nodeID)) {
      continue
    }
    currentJuneoStake += BigInt(validator.stakeAmount)
    if (!validator.connected) {
      disconnected.set(validator.nodeID, validator)
    }
    const uptime = `${Math.round(validator.uptime)}%`
    const startTime = new Date(Number(validator.startTime) * 1000)
    const endTime = new Date(Number(validator.endTime) * 1000)
    const elapsedTimeValue = Math.floor(
      (new Date().getTime() - startTime.getTime()) / 1000 / 86400,
    )
    const elapsedTime = `${elapsedTimeValue} days`
    const remainingTime = `${
      Math.floor((endTime.getTime() - startTime.getTime()) / 1000 / 86400) -
      elapsedTimeValue
    } days`
    const decimals = provider.platformChain.asset.decimals
    const stake = `${new AssetValue(
      validator.stakeAmount,
      decimals,
    ).getReadableValue()} ${provider.platformChain.asset.name}`
    const potentialReward = `${new AssetValue(
      validator.potentialReward,
      decimals,
    ).getReadableValue()} ${provider.platformChain.asset.name}`
    const data: ValidatorInfo = {
      nodeID: validator.nodeID,
      connected: validator.connected,
      uptime,
      startTime,
      endTime,
      elapsedTime,
      remainingTime,
      stake,
      potentialReward,
    }
    console.log(`found ${validator.nodeID}:`)
    console.log(data)
    juneoValidators.push(data)
    found.set(validator.nodeID, validator)
    if (found.size === validators.length) {
      break
    }
  }
  if (disconnected.size < 1) {
    console.log(`all validators are connected on the ${network} network`)
  } else {
    console.log(`list of disconnected validators on the ${network} network:`)
    for (const [nodeId] of disconnected) {
      console.log(nodeId)
    }
  }
  if (found.size >= validators.length) {
    console.log(`no missing validators on the ${network} network`)
  } else {
    console.log(`list of missing validators on the ${network} network:`)
    for (const nodeId of validators) {
      if (!found.has(nodeId)) {
        console.log(nodeId)
      }
    }
  }
  const formattedJuneoStake = new AssetValue(
    currentJuneoStake,
    provider.platformChain.asset.decimals,
  )
  console.log(
    `current Juneo stake: ${formattedJuneoStake.getReadableValueRounded(
      2,
    )} JUNE`,
  )
  juneoValidators.sort((a: ValidatorInfo, b: ValidatorInfo) => {
    return a.endTime.getTime() - b.endTime.getTime()
  })
  return {
    juneoValidators,
    disconnected,
    found,
    validators,
    formattedJuneoStake: `Current Juneo stake: ${formattedJuneoStake.getReadableValueRounded(
      2,
    )} JUNE`,
  }
}

export default fetchJuneoValidators
