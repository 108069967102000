import "./CreateTransaction.scss";

import { Address, Blockchain } from "juneojs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRedo, FaSort } from "react-icons/fa";

import { GrAdd, GrClose } from "../../../assets/svg/svgIcons";
import {
  Button,
  ButtonSwitcher,
  Card,
  FormatDate,
  StyledInputAddress,
  StyledInputNumber,
  StyledInputText,
} from "../../../mcn-react-ui";
import DynamicTable from "../../../mcn-react-ui/DynamicTable/DynamicTable";
import FormatTransaction from "../../../mcn-react-ui/Utils/Format/FormatTransaction/FormatTransaction";
import useNetworkStore from "../../../stores/NetworkStore";
import appFetch from "../../../utils/services/Fetch/appFetch";
import { ReceiverAddress, Vesting } from "../TokenDistribution";
import { formatAmount } from "../ValidateTransaction/ValidateTransaction";
import CreateVesting from "./CreateVesting/CreateVesting";

type Stakeholder = {
  address: string;
  stakeholder: string;
};

const CreateTransaction = () => {
  const [transactions, setTransactions] = useState<ReceiverAddress[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<
    ReceiverAddress[]
  >([]);

  const [totalAmount, setTotalAmount] = useState<string | null>(null);
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([
    {
      address: "",
      stakeholder: "",
    },
  ]);
  const [vestingType, setVestingType] = useState<string>("");
  const [vestings, setVestings] = useState<Vesting[]>([]);
  const [sended, setSended] = useState<boolean>(false);
  const { provider, network } = useNetworkStore();

  const [blockchain, setBlockchain] = useState<Blockchain>(provider.jvmChain);

  useEffect(() => {
    setBlockchain(provider.jvmChain);
  }, [provider]);

  useEffect(() => {
    try {
      const fetchAddresses = async () => {
        const response = await appFetch(
          `/api/transactions/getTransactions?networkId=${provider.mcn.id}`,
        );
        setTransactions(response);
      };

      fetchAddresses();
    } catch (error) {
      console.error("Error:", error);
    }
  }, [blockchain]);

  useEffect(() => {
    // boucles on every address to check if they are valid
    // IF yes, check if there are a transaction for the address and add it to filtered
    for (const address of stakeholders) {
      if (isValidAddress(address.address)) {
        const transaction = transactions.find(
          (transaction) => transaction.address === address.address,
        );
        if (transaction) {
          // check if not already in filtered
          if (
            !filteredTransactions.find(
              (filteredTransaction) =>
                filteredTransaction.address === transaction.address,
            )
          ) {
            setFilteredTransactions([...filteredTransactions, transaction]);
          }
        }
      }
    }
  }, [stakeholders]);

  const handleCreateVesting = async () => {
    try {
      // check if the sum of the vestings is equal to the total amount
      const sum = vestings.reduce(
        (acc, vesting) => acc + Number(vesting.amount),
        0,
      );
      if (sum !== Number(totalAmount)) {
        toast.error("The sum of the vestings is not equal to the total amount");
        return;
      }

      // check if all stakeholders have a stakeholder name
      if (stakeholders.some((stakeholder) => stakeholder.stakeholder === "")) {
        toast.error("Please enter a stakeholder name for each address");
        return;
      }

      const response = await appFetch("/api/transactions/createTransaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stakeholders,
          blockchain_id: blockchain.id,
          vestingType,
          vestings,
          networkId: provider.mcn.id,
        }),
      });
      if (response) {
        toast.success("Vesting created");
        // set button to disabled
        setSended(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isValidAddress = (address: string) => {
    try {
      const validAddress = new Address(address).encode(provider.mcn.hrp);
      if (!validAddress) {
        return false;
      }
      if (blockchain.id === provider.jvmChain.id) {
        return provider.mcn.primary.jvm.validateAddress(address);
      } else if (blockchain.id === provider.platformChain.id) {
        return provider.mcn.primary.platform.validateAddress(address);
      }
    } catch {
      return false;
    }
  };

  useEffect(() => {
    setSended(false);
  }, [stakeholders, totalAmount, vestings]);

  const generateVestings = () => {
    if (totalAmount) {
      // create 4 vesting :
      // 20% with vesting date today,   20% Vesting q4, 30 % Vesting q1 , 30 % Vesting q 2
      setVestings([
        {
          amount: ((Number(totalAmount) * 20) / 100).toString(),
          releaseTime: undefined,
          validated: false,
          vestingId: Math.random().toString(36).substring(7),
        },
        {
          amount: ((Number(totalAmount) * 20) / 100).toString(),
          // new Date() + 3 months
          releaseTime: new Date(new Date().setMonth(new Date().getMonth() + 3)),
          validated: false,
          vestingId: Math.random().toString(36).substring(7),
        },
        {
          amount: ((Number(totalAmount) * 30) / 100).toString(),
          // new Date() + 6 months
          releaseTime: new Date(new Date().setMonth(new Date().getMonth() + 6)),
          validated: false,
          vestingId: Math.random().toString(36).substring(7),
        },
        {
          amount: ((Number(totalAmount) * 30) / 100).toString(),
          // new Date() + 9 months
          releaseTime: new Date(new Date().setMonth(new Date().getMonth() + 9)),
          validated: false,
          vestingId: Math.random().toString(36).substring(7),
        },
      ]);
    } else {
      toast.error("Please enter a total amount");
    }
  };

  const setDateWithCurrentTime = (
    year: number,
    month: number,
    day: number,
  ): Date => {
    const now = new Date(); // Obtenir la date et l'heure actuelles
    const dateWithTime = new Date(year, month, day); // Créer la date spécifique sans l'heure

    // Appliquer l'heure actuelle à cette date spécifique
    dateWithTime.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds(),
    );

    return dateWithTime;
  };

  const generateCoreVestings = () => {
    // create 5 vesting :
    // 20% with vesting date today,   20% Vesting q4, 30 % Vesting q1 , 30 % Vesting q 2
    setVestings([
      {
        amount: (0).toString(),
        releaseTime: new Date(),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: (0).toString(),
        // heure actuale with date 2024, 12, 31
        releaseTime: setDateWithCurrentTime(2024, 11, 31),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: (0).toString(),
        releaseTime: setDateWithCurrentTime(2025, 2, 31),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: (0).toString(),
        releaseTime: setDateWithCurrentTime(2025, 5, 30),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: (0).toString(),
        releaseTime: setDateWithCurrentTime(2025, 8, 30),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
    ]);
  };

  const generateCommunityVestings = () => {
    // create 5 vesting :
    setVestings([
      {
        amount: ((Number(totalAmount) * 20) / 100).toString(),
        releaseTime: new Date(),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: ((Number(totalAmount) * 20) / 100).toString(),
        // heure actuale with date 2024, 12, 31
        releaseTime: new Date(new Date().setMonth(new Date().getMonth() + 3)),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: ((Number(totalAmount) * 30) / 100).toString(),
        releaseTime: new Date(new Date().setMonth(new Date().getMonth() + 6)),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
      {
        amount: ((Number(totalAmount) * 30) / 100).toString(),
        releaseTime: new Date(new Date().setMonth(new Date().getMonth() + 12)),
        validated: false,
        vestingId: Math.random().toString(36).substring(7),
      },
    ]);
  };

  const collumns = [
    {
      title: "Amount",
      dataIndex: "amount",
      render: (value: bigint, transaction: ReceiverAddress) => {
        return (
          <div>
            {formatAmount(
              value,
              transaction.blockchainId,
              transaction.networkId,
            )}{" "}
            JUNE
          </div>
        );
      },
    },
    {
      title: "Lock Time",
      dataIndex: "releaseTime",
      render: (value: string) => {
        return value === "0" ? (
          "Today"
        ) : (
          <FormatDate timestamp={new Date(parseInt(value)).getTime()} />
        );
      },
    },
    {
      title: "Validated",
      dataIndex: "validated",
      render: (value: boolean) => {
        return <span className={value ? "green" : "red"}></span>;
      },
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      render: (value: string | null, transaction: ReceiverAddress) => {
        return value ? (
          <FormatTransaction
            transactionId={value}
            explorer={`https://${network.id}.mcnscan.io/chain/${transaction.blockchainId}/`}
            start={5}
            end={5}
          />
        ) : (
          "Not sent"
        );
      },
    },
  ];

  const handleReset = () => {
    setStakeholders([
      {
        address: "",
        stakeholder: "",
      },
    ]);
    setTotalAmount("");
    setVestings([]);
    setVestingType("");
    setSended(false);
  };

  const handleAddStakeholder = (value: string, index: number) => {
    const newStakeholders = [...stakeholders];

    if (!isValidAddress(value)) {
      newStakeholders[index].address = value;
      setStakeholders(newStakeholders);
    } else {
      newStakeholders[index].address = value;

      // get stakeholder name
      const transaction = transactions.find(
        (transaction) => transaction.address === value,
      );
      if (transaction) {
        newStakeholders[index].stakeholder = transaction.metadata;
      } else {
        newStakeholders[index].stakeholder = "";
      }
      setStakeholders(newStakeholders);
    }
  };

  const handleDeleteStakeholder = (index: number) => {
    const newStakeholders = [...stakeholders];
    newStakeholders.splice(index, 1);
    setStakeholders(newStakeholders);

    // remove from filtered
    const newFiltered = [...filteredTransactions];
    // find the index of the transaction to remove
    const indexToRemove = newFiltered.findIndex(
      (transaction) => transaction.address === stakeholders[index].address,
    );
    newFiltered.splice(indexToRemove, 1);
    setFilteredTransactions(newFiltered);
  };

  const handleConvertAddress = () => {
    // boucle on each stakeholder to convert the address with the selected chain
    const newStakeholders = [...stakeholders];

    for (const stakeholder of newStakeholders) {
      try {
        const validAddress = new Address(stakeholder.address).encode(
          provider.mcn.hrp,
        );
        if (!validAddress) {
          return false;
        }
        if (blockchain.id === provider.jvmChain.id) {
          newStakeholders[newStakeholders.indexOf(stakeholder)].address =
            "JVM-" + validAddress;
        } else if (blockchain.id === provider.platformChain.id) {
          newStakeholders[newStakeholders.indexOf(stakeholder)].address =
            "P-" + validAddress;
        }
      } catch {
        return false;
      }
    }
    setStakeholders(newStakeholders);
  };
  return (
    <div className="createTransaction">
      <ButtonSwitcher
        value={blockchain}
        setValue={setBlockchain}
        datas={[provider.jvmChain, provider.platformChain]}
      />

      <div className="addressTitle">
        <span>Receiver addresses</span>
        <Button
          onClick={handleConvertAddress}
          style="square"
          icon={<FaSort />}
        />
      </div>

      {stakeholders.map((address, index) => (
        <div className="recipientRow" key={address.address + "txforaddress"}>
          <StyledInputAddress
            value={address.address}
            setValue={(value) => handleAddStakeholder(value, index)}
            className="receiverAddress"
            placeholder={
              blockchain.id === provider.jvmChain.id ? "JVM-..." : "P-..."
            }
            isValid={isValidAddress(address.address)}
          />
          <StyledInputText
            value={address.stakeholder}
            setValue={(value) => {
              const newAddresses = [...stakeholders];
              newAddresses[index].stakeholder = value;
              setStakeholders(newAddresses);
            }}
            label=""
            placeholder="Stakeholder"
          />
          <Button
            className="removeButton"
            onClick={() => handleDeleteStakeholder(index)}
            icon={<GrClose />}
            style="square"
            disabled={stakeholders.length === 1}
          />
        </div>
      ))}
      <Button
        icon={<GrAdd />}
        style="square"
        className="addButton"
        onClick={() =>
          setStakeholders([
            ...stakeholders,
            {
              address: "",
              stakeholder: "",
            },
          ])
        }
      />

      {filteredTransactions &&
        filteredTransactions.map((transaction) => (
          <Card
            key={transaction.address + Math.random().toString(36).substring(7)}
          >
            We have already transactions for this address !
            <span>
              {transaction.address} - {transaction.metadata} -{" "}
              {transaction.vestingType}
            </span>{" "}
            <span>
              Total amount:{" "}
              {formatAmount(
                transaction.totalAmount,
                transaction.blockchainId,
                transaction.networkId,
              )}{" "}
              JUNE
            </span>
            <DynamicTable
              data={transaction.vestings}
              columns={collumns}
              mobile
            />
          </Card>
        ))}
      <div className="totalAmount">
        <StyledInputNumber
          value={totalAmount}
          setValue={setTotalAmount}
          label="Total Amount"
        />
        <Button onClick={() => generateCommunityVestings()}>
          Generate Community vestings
        </Button>
        <Button onClick={() => generateVestings()}>Generate vestings</Button>
        <Button onClick={() => generateCoreVestings()}>
          Generate core vestings
        </Button>
      </div>

      <CreateVesting
        vestings={vestings}
        setVestings={setVestings}
        totalAmount={totalAmount}
      />
      <StyledInputText
        value={vestingType}
        setValue={setVestingType}
        label="Vesting type"
      />

      <div className="buttonContainer">
        <Button
          onClick={() => handleCreateVesting()}
          disabled={!totalAmount || !stakeholders || !vestingType || sended}
        >
          Create vesting
        </Button>
        <Button onClick={() => handleReset()} style="square">
          <FaRedo />
        </Button>
      </div>
    </div>
  );
};

export default CreateTransaction;
