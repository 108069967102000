import { NodeId, PeersResponse } from 'juneojs'
import { selectNetworkProvider } from './common/networks'

// example usage in terminal
// npx ts-node ./scripts/run.ts
async function trackPeerNodeID(
  network: string | undefined,
  nodeID: string | undefined,
) {
  const provider = selectNetworkProvider(network)
  if (nodeID === undefined) {
    console.log('missing argument to execute script please provide a nodeID')
    return
  }
  try {
    new NodeId(nodeID)
  } catch {
    console.log(
      `provided nodeID is not valid: "${nodeID}" is it properly formatted?`,
    )
    return
  }
  const peers: PeersResponse = await provider.info.peers([nodeID])
  if (peers.numPeers < 1) {
    console.log(`could not find a peer with nodeID: ${nodeID}`)
    throw new Error('peer not found')
  } else {
    console.log('found peer: ')
    console.log(peers.peers[0])
    return peers.peers[0]
  }
}

export default trackPeerNodeID
