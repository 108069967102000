import "./LatestValidators.scss";

import { useState } from "react";

import Amount from "../../components/Utils/Amount/Amount";
import {
  ButtonSwitcher,
  Container,
  ContainerContent,
} from "../../mcn-react-ui";
import FetchLatestValidators from "../../mcn-scripts/juneojs/components/FetchLatestValidators";
import useNetworkStore, { networkList } from "../../stores/NetworkStore";

const LatestValidators = () => {
  const [amount, setAmount] = useState<string>("10");

  const { network, setNetwork } = useNetworkStore();

  return (
    <Container>
      <ContainerContent className="latestValidatorsPageDiv">
        <ButtonSwitcher
          datas={networkList}
          value={network}
          setValue={setNetwork}
        />
        <Amount
          value={amount}
          onChange={(value) => setAmount(value || "0")}
          min="1"
          className="amountInput"
        />

        <FetchLatestValidators network={network.id} amount={amount} />
      </ContainerContent>
    </Container>
  );
};

export default LatestValidators;
