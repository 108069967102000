import "./NodeId.scss";

import React from "react";

interface NodeIdProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const InputNodeId: React.FC<NodeIdProps> = ({ value, onChange, className }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue !== "") {
      onChange(newValue);
    } else {
      onChange("");
    }
  };

  return (
    <div className={`nodeIdContainer ${className ? className : ""}`}>
      <label htmlFor={`nodeIdInput${className ? className : ""}`}>NodeId</label>
      <div>
        <input
          placeholder="Node-"
          id={`nodeIdInput${className ? className : ""}`}
          type="text"
          value={value || ""}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default InputNodeId;
