import { useState } from 'react'

import { Button } from '../../../mcn-react-ui'
import fetchLatestValidators from '../scripts/fetchLatestValidators'
import ErrorComponent from './ErrorComponent'
import ValidatorCard, { ValidatorInfo } from './ValidatorCard/ValidatorCard'

interface FetchLatestValidatorsProps {
  network: string
  amount: string
}
const FetchLatestValidators = ({
  network,
  amount,
}: FetchLatestValidatorsProps) => {
  const [latestValidators, setLatestValidators] = useState<ValidatorInfo[]>()
  const [latestValidatorsError, setLatestValidatorsError] = useState<
    string | undefined
  >()

  const getLatestValidators = async () => {
    if (amount === undefined) {
      console.log('missing argument to execute script please provide a amount')
      return
    }
    try {
      setLatestValidators(await fetchLatestValidators(network, amount))
      setLatestValidatorsError(undefined)
    } catch (error) {
      console.error('Error fetching network data:', error)
      if (error instanceof Error) {
        setLatestValidatorsError(error.message)
      }
      setLatestValidators(undefined)
    }
  }

  return (
    <>
      <Button onClick={getLatestValidators} className="submit">
        Get Latest Validator
      </Button>
      <div className="latestValidatorsList">
        {latestValidators &&
          latestValidators.map((validator) => (
            <ValidatorCard key={validator.nodeID} validator={validator} />
          ))}
        {latestValidatorsError && (
          <ErrorComponent error={latestValidatorsError} />
        )}
      </div>
    </>
  )
}

export default FetchLatestValidators
