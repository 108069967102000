import { GrAdd, GrClose } from "../../../../assets/svg/svgIcons";
import {
  Button,
  Calendar,
  Card,
  StyledInputNumber,
} from "../../../../mcn-react-ui";
import DynamicTable from "../../../../mcn-react-ui/DynamicTable/DynamicTable";
import { Vesting } from "../../TokenDistribution";

interface CreateVestingProps {
  vestings: Vesting[];
  setVestings: (vestings: Vesting[]) => void;
  totalAmount: string | null;
}

const CreateVesting = ({
  vestings,
  setVestings,
  totalAmount,
}: CreateVestingProps) => {
  const handleChangeVestingAmount = (
    vestingId: string,
    newAmount: string | null,
  ) => {
    if (newAmount === null) return;
    const updatedVestings = vestings.map((vesting) =>
      vesting.vestingId === vestingId
        ? { ...vesting, amount: newAmount }
        : vesting,
    );
    setVestings(updatedVestings);
  };

  const handleChangeVestingDate = (vestingId: string, newDate: Date) => {
    const updatedVestings = vestings.map((vesting) =>
      vesting.vestingId === vestingId
        ? { ...vesting, releaseTime: newDate }
        : vesting,
    );
    setVestings(updatedVestings);
  };

  // Handler pour retirer un vesting
  const handleRemoveVesting = (vestingId: string) => {
    const updatedVestings = vestings.filter(
      (vesting) => vesting.vestingId !== vestingId,
    );
    setVestings(updatedVestings);
  };

  const vestingColumns = [
    {
      title: "Amount",
      dataIndex: "amount",
      colunmWidth: "30%",
      render: (value: bigint, record: Vesting) => (
        <>
          <StyledInputNumber
            value={value.toString()}
            setValue={(newValue) =>
              handleChangeVestingAmount(record.vestingId, newValue)
            }
          />
          <span>
            {value !== null && totalAmount !== null && totalAmount !== "0"
              ? `(${((Number(value) / Number(totalAmount)) * 100).toFixed(2)}%)`
              : ""}
          </span>
        </>
      ),
    },
    {
      title: "Vested date",
      dataIndex: "releaseTime",
      colunmWidth: "30%",
      render: (value: Date, record: Vesting) => (
        <Calendar
          selectedDate={value}
          setSelectedDate={(newDate) =>
            handleChangeVestingDate(record.vestingId, newDate)
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "vestingId",
      render: (value: string) => (
        // Button to remove the vesting
        <Button
          onClick={() => {
            handleRemoveVesting(value);
          }}
          style="square"
          icon={<GrClose />}
          disabled={vestings.length === 1}
        />
      ),
    },
  ];

  return (
    <div className="advancedFeatures">
      <Card>
        <h4>Vestings</h4>
        <>
          <DynamicTable columns={vestingColumns} data={vestings} />
          <Button
            onClick={() => {
              setVestings([
                ...vestings,
                {
                  amount: "0",
                  releaseTime: new Date(),
                  validated: false,
                  // random id
                  vestingId: Math.random().toString(36).substring(7),
                },
              ]);
            }}
            icon={<GrAdd />}
            style="square"
            className="addButton"
          />
        </>
      </Card>
    </div>
  );
};

export default CreateVesting;
