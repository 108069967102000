import "./Home.scss";

import { useState } from "react";

import InputNodeId from "../../components/Utils/NodeId/NodeId";
import {
  ButtonSwitcher,
  Container,
  ContainerContent,
} from "../../mcn-react-ui";
import TrackPeerNodeID from "../../mcn-scripts/juneojs/components/TrackPeerNodeID";
import useNetworkStore, { networkList } from "../../stores/NetworkStore";

const Home = () => {
  const [nodeID, setNodeID] = useState<string>("");
  const { network, setNetwork } = useNetworkStore();

  return (
    <Container>
      <ContainerContent className="homePageDiv">
        <ButtonSwitcher
          datas={networkList}
          value={network}
          setValue={setNetwork}
        />
        <InputNodeId value={nodeID} onChange={setNodeID} />

        <TrackPeerNodeID network={network.id} nodeID={nodeID} />
      </ContainerContent>
    </Container>
  );
};

export default Home;
