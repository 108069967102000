import "./Login.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Container,
  ContainerContent,
  StyledInputPassword,
  StyledInputText,
} from "../../mcn-react-ui";
import useUserStore from "../../stores/UserStore";
import appFetch from "../../utils/services/Fetch/appFetch";

const Login = () => {
  const [password, setPassword] = useState("");
  const [username, setName] = useState("");
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useUserStore();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const route = "/api/auth/login";
    const body = { username, password };

    try {
      const response = await appFetch(route, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response) {
        // redirect to home page
        setIsAuthenticated(true);
        navigate("/");

        toast.success(response.message);
      } else {
        toast.error("wrong credentials");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <Container>
      <ContainerContent>
        <form onSubmit={handleSubmit}>
          <StyledInputText
            label="Username"
            value={username}
            setValue={setName}
            required={true}
            className="inputLogin"
          />
          <StyledInputPassword
            label="Password"
            value={password}
            setValue={setPassword}
            required={true}
            className="inputLogin"
          />
          <Button type="submit" label={"login"} className="loginSubmitButton" />
        </form>
      </ContainerContent>
    </Container>
  );
};

export default Login;
