import { AssetValue } from 'juneojs'

import { selectNetworkProvider } from './common/networks'

export type NetworkData = {
  peersOnline: string
  peersVersions: Map<string, number>
  validatorsOnline: string
  validatorsUptimes: Map<number, number>
  validUptimes: string
  meanUptime: string
  medianUptime: string
  totalStake: string
  connectedStake: string
  rewardingStake: string
  pendingRewards: string
  pendingValidRewards: string
}

// example usage in terminal

// npx ts-node ./scripts/getNetworkData.ts socotra

async function getNetworkData(
  network: string | undefined,
): Promise<NetworkData> {

  const provider = selectNetworkProvider(network)
  const peers = await provider.info.peers()
  const peersVersions = new Map<string, number>()
  for (const peer of peers.peers) {
    const key = peer.version
    let amount = 0
    if (peersVersions.has(key)) {
      amount = peersVersions.get(key) ?? 0
    }
    peersVersions.set(key, amount + 1)
  }
  const validatorsUptimes = new Map<number, number>()
  // init all uptimes
  for (let i = 0; i < 101; i++) {
    validatorsUptimes.set(i, 0)
  }
  let connected = BigInt(0)
  let connectedStake = BigInt(0)
  let okUptimes = BigInt(0)
  let meanUptime = 0
  let rewardingStake = BigInt(0)
  let totalStake = BigInt(0)
  let pendingRewards = BigInt(0)
  let pendingValidRewards = BigInt(0)
  const currentVals = await provider.platformApi.getCurrentValidators()
  const valsCount = currentVals.validators.length
  const uptimes: number[] = []
  for (const val of currentVals.validators) {
    if (val.connected) {
      connected++
      connectedStake += BigInt(val.stakeAmount)
    }

    const roundedUptime = Math.round(val.uptime)
    const totalUptimes = validatorsUptimes.get(roundedUptime) ?? 0
    validatorsUptimes.set(roundedUptime, totalUptimes + 1)
    if (val.uptime >= 80) {
      okUptimes++
      rewardingStake += BigInt(val.stakeAmount)
      pendingValidRewards += BigInt(val.potentialReward)
    }
    meanUptime += Number(val.uptime)
    uptimes.push(val.uptime)
    totalStake += BigInt(val.stakeAmount)
    pendingRewards += BigInt(val.potentialReward)
  }
  const medianUptime = uptimes.sort((a: number, b: number) => {
    return a - b
  })[Math.round(uptimes.length / 2)]
  meanUptime /= valsCount
  const juneDecimals = provider.platformChain.asset.decimals

  const networkData: NetworkData = {
    peersOnline: `${peers.numPeers} peers online`,
    peersVersions,
    validatorsOnline: `${connected}/${valsCount} online validators (${new AssetValue(
      (connected * BigInt(1000000)) / BigInt(valsCount),
      4,
    ).getReadableValueRounded()}%)`,
    validatorsUptimes,
    validUptimes: `${okUptimes}/${valsCount} uptimes >= 80% (${new AssetValue(
      (okUptimes * BigInt(1000000)) / BigInt(valsCount),
      4,
    ).getReadableValueRounded()}%)`,
    meanUptime: `mean uptime: ${Math.round(meanUptime)}%`,
    medianUptime: `median uptime: ${Math.round(medianUptime)}%`,
    totalStake: `total stake: ${new AssetValue(
      totalStake,
      juneDecimals,
    ).getReadableValueRounded()} JUNE`,
    connectedStake: `connected stake: ${new AssetValue(
      connectedStake,
      juneDecimals,
    ).getReadableValueRounded()} JUNE (${new AssetValue(
      (connectedStake * BigInt(1000000)) / totalStake,
      4,
    ).getReadableValueRounded()}%)`,
    rewardingStake: `rewarding stake: ${new AssetValue(
      rewardingStake,
      juneDecimals,
    ).getReadableValueRounded()} JUNE (${new AssetValue(
      (rewardingStake * BigInt(1000000)) / totalStake,
      4,
    ).getReadableValueRounded()}%)`,
    pendingRewards: `Pending rewards: ${new AssetValue(
      pendingRewards,
      juneDecimals,
    ).getReadableValueRounded()} JUNE potential rewards`,
    pendingValidRewards: `${new AssetValue(
      pendingValidRewards,
      juneDecimals,
    ).getReadableValueRounded()} JUNE valid potential rewards (${new AssetValue(
      (pendingValidRewards * BigInt(1000000)) / pendingRewards,
      4,
    ).getReadableValueRounded()}%)`,
  }

  // loop on the objects to display the data in terminal
  for (const key in networkData) {
    if (key === 'validatorsUptimes') {
      continue
    }
    console.log(networkData[key as keyof NetworkData])
  }

  return networkData
}

export default getNetworkData
