import { useMemo } from "react";

export const DOTS = "...";

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface UsePaginationProps {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}
export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: UsePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Always calculate the number of pages to show
    const totalPageNumbers = siblingCount + 5;

    // When the number of pages is less than the page numbers we want to show, return full range
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Determine indexes for visible siblings
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    // Determine conditions for showing dots
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    // Initialize result with possible first and last pages
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    let result = [];

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(1, 3 + 2 * siblingCount);
      result = [...leftRange, DOTS, totalPageCount];
    } else if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(
        totalPageCount - (2 + 2 * siblingCount),
        totalPageCount,
      );
      result = [firstPageIndex, DOTS, ...rightRange];
    } else if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      result = [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    } else {
      // This case handles when all pages are visible without dots
      result = range(firstPageIndex, lastPageIndex);
    }

    return result;
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
