// import "./PrivateRoutes.scss";

import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import useVerifyToken from "../../hooks/useVerifyToken";
import { Spinner } from "../../mcn-react-ui";
import useUserStore from "../../stores/UserStore";

const PrivateRoutes = () => {
  const { isAuthenticated } = useUserStore();

  const { verifyToken, isLoading } = useVerifyToken();

  useEffect(() => {
    verifyToken();
  }, []);

  if (isLoading) {
    return (
      <div className="walletConnectPromptDiv">
        <Spinner />
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
