import "./HelpMeWith.scss";

import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import useWindowWidth from "../hooks/useWindowWidth";
import Information from "./information.svg?react";

interface HelpMeWithProps {
  text: string;
  className?: string;
}

const HelpMeWith = ({ text, className }: HelpMeWithProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const { isTabelet, windowWidth } = useWindowWidth();
  const iconRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (iconRef.current && tooltipRef.current) {
      const iconRect = (iconRef.current as HTMLElement).getBoundingClientRect();
      const tooltipRect = (
        tooltipRef.current as HTMLElement
      ).getBoundingClientRect();
      let leftPosition;

      if (isTabelet) {
        // Align right for mobile screens
        leftPosition = iconRect.right - tooltipRect.width + 10;
      } else {
        // Center align for larger screens
        leftPosition =
          iconRect.left +
          window.scrollX +
          iconRect.width / 2 -
          tooltipRect.width / 2;
      }

      // Make sure the tooltip does not go off-screen
      leftPosition = Math.max(leftPosition, 0);
      leftPosition = Math.min(leftPosition, windowWidth - tooltipRect.width);

      setPosition({
        top: iconRect.top + window.scrollY + iconRect.height + 5,
        left: leftPosition,
      });
    }
  }, [iconRef, tooltipRef, showTooltip, windowWidth]);

  const handleMouseOver = () => setShowTooltip(true);
  const handleMouseOut = () => setShowTooltip(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowTooltip(false);
    }
  };

  // when user press escape key, close tooltip
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const tooltip = showTooltip && (
    <div
      ref={tooltipRef}
      className="helpMeWithTooltip"
      style={{
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 9999,
      }}
    >
      {text}
    </div>
  );

  return (
    <button
      className={`helpMeWith ${className ?? ""}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleMouseOver}
      ref={iconRef}
      tabIndex={0}
    >
      <Information />
      {createPortal(tooltip, document.body)}
    </button>
  );
};

export default HelpMeWith;
