import { useState } from 'react'

import { Button } from '../../../mcn-react-ui'
import trackValidatorNodeID from '../scripts/trackValidatorNodeID'
import ErrorComponent from './ErrorComponent'
import ValidatorCard, { ValidatorInfo } from './ValidatorCard/ValidatorCard'

interface TrackValidatorNodeIDProps {
  network: string
  nodeID: string
}
const TrackValidatorNodeID = ({
  network,
  nodeID,
}: TrackValidatorNodeIDProps) => {
  const [validatorInfo, setValidatorInfo] = useState<ValidatorInfo>()
  const [validatorInfoError, setValidatorInfoError] = useState<
    string | undefined
  >()

  const getValidatorInfo = async () => {
    if (nodeID === undefined) {
      console.log('missing argument to execute script please provide a nodeID')
      return
    }
    try {
      setValidatorInfo(await trackValidatorNodeID(network, nodeID))
      setValidatorInfoError(undefined)
    } catch (error) {
      console.error('Error fetching network data:', error)
      if (error instanceof Error) {
        setValidatorInfoError(error.message)
      }
      setValidatorInfo(undefined)
    }
  }

  return (
    <>
      <Button onClick={getValidatorInfo} className="submit">
        Track Validator
      </Button>
      <div className="latestValidatorsList">
        {validatorInfo && (
          <ValidatorCard validator={validatorInfo} key={validatorInfo.nodeID} />
        )}
        {validatorInfoError && <ErrorComponent error={validatorInfoError} />}
      </div>
    </>
  )
}

export default TrackValidatorNodeID
