import { Peer } from 'juneojs'
import { useState } from 'react'

import { Button } from '../../../mcn-react-ui'
import trackPeerNodeID from '../scripts/trackPeerNodeID'
import ErrorComponent from './ErrorComponent'

interface TrackPeerNodeIDProps {
  network: string
  nodeID: string
}
const TrackPeerNodeID = ({ network, nodeID }: TrackPeerNodeIDProps) => {
  const [peerInfo, setPeerInfo] = useState<Peer>()
  const [peerInfoError, setPeerInfoError] = useState<string | undefined>()

  const getPeerInfo = async () => {
    if (nodeID === undefined) {
      console.log('missing argument to execute script please provide a nodeID')
      return
    }
    try {
      setPeerInfo(await trackPeerNodeID(network, nodeID))
      setPeerInfoError(undefined)
    } catch (error) {
      console.error('Error fetching network data:', error)
      if (error instanceof Error) {
        setPeerInfoError(error.message)
      }
      setPeerInfo(undefined)
    }
  }

  const isPeerActive = (
    observedUptime: number,
    lastSent: string,
    lastReceived: string,
  ): boolean => {
    // Check observedUptime
    const isUptimeGood = observedUptime > 90

    // Convert lastSent and lastReceived to Date objects
    const lastSentDate = new Date(lastSent)
    const lastReceivedDate = new Date(lastReceived)

    // Get current date and time
    const currentDate = new Date()

    // Calculate time elapsed since lastSent and lastReceived in minutes
    const elapsedSinceLastSent =
      (currentDate.getTime() - lastSentDate.getTime()) / (1000 * 60)
    const elapsedSinceLastReceived =
      (currentDate.getTime() - lastReceivedDate.getTime()) / (1000 * 60)

    // Check if lastSent and lastReceived are recent
    const isRecentlyActive =
      elapsedSinceLastSent < 5 && elapsedSinceLastReceived < 5

    // Final evaluation of peer's activity
    return isUptimeGood && isRecentlyActive
  }

  return (
    <>
      <Button onClick={getPeerInfo} className="submit">
        Get Peer Info
      </Button>
      {peerInfo && !peerInfoError && (
        <div className="dataDisplay">
          <div className="dataField">
            <label>active: </label>
            <span>
              {isPeerActive(
                peerInfo.observedUptime,
                peerInfo.lastSent,
                peerInfo.lastReceived,
              ).toString()}
            </span>
          </div>
          <div className="dataField">
            <label>lastReceived: </label>
            <span>{peerInfo.lastReceived}</span>
          </div>
          <div className="dataField">
            <label>nodeID: </label>
            <span>{peerInfo.nodeID}</span>
          </div>
          <div className="dataField">
            <label>observedUptime: </label>
            <span>{peerInfo.observedUptime}</span>
          </div>
          <div className="dataField">
            <label>IP: </label>
            <span>{peerInfo.publicIP}</span>
          </div>
        </div>
      )}
      {peerInfoError && <ErrorComponent error={peerInfoError} />}
    </>
  )
}

export default TrackPeerNodeID
