import "./Amount.scss";

import React, { useEffect, useState } from "react";

interface AmountProps {
  min: string;
  max?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  className?: string;
  valid?: boolean;
}

const Amount: React.FC<AmountProps> = ({
  min,
  max,
  value,
  onChange,
  className,
  valid = true,
}) => {
  const [inputStrValue, setInputStrValue] = useState<string>(value || "");

  useEffect(() => {
    setInputStrValue(value || "");
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Check if input is a valid number with either dot or comma
    const isValidInput = /^(\d+([.,]\d*)?)?$/.test(inputValue);

    if (!isValidInput) return; // Don't process invalid input

    // Replace comma with dot
    inputValue = inputValue.replace(/,/g, ".");

    setInputStrValue(inputValue);

    // if > max, set to max
    if (max && parseFloat(inputValue) > parseFloat(max)) {
      onChange(max);
      setInputStrValue(max);
      return;
    }

    if (inputValue !== "") {
      onChange(inputValue);
    } else {
      onChange(null);
      setInputStrValue("");
    }
  };

  return (
    <div className={`amountContainer ${className ? className : ""}`}>
      <label htmlFor={`amountInput${className ? className : ""}`}>Amount</label>
      <div className={`${!valid ? "invalid" : ""}`}>
        <input
          placeholder="0"
          id={`amountInput${className ? className : ""}`}
          min={min}
          max={max}
          type="text"
          value={inputStrValue}
          onChange={handleInputChange}
          step="0.01"
        />
      </div>
    </div>
  );
};

export default Amount;
