import "./CreateTransaction.scss";

import { Address, Blockchain } from "juneojs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRedo, FaSort } from "react-icons/fa";

import { GrAdd, GrClose } from "../../../assets/svg/svgIcons";
import {
  Button,
  StyledDropdown,
  StyledInputAddress,
  StyledInputNumber,
  StyledInputText,
} from "../../../mcn-react-ui";
import useNetworkStore from "../../../stores/NetworkStore";
import appFetch from "../../../utils/services/Fetch/appFetch";
import { ReceiverAddress } from "../SendToken";

type Stakeholder = {
  address: string;
  stakeholder: string;
  amount: string;
  blockchain: Blockchain;
  blockchain_id: string;
};

const CreateTransaction = () => {
  const [filteredTransactions, setFilteredTransactions] = useState<
    ReceiverAddress[]
  >([]);
  const { provider } = useNetworkStore();

  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([
    {
      address: "",
      stakeholder: "",
      amount: "",
      blockchain: provider.juneChain,
      blockchain_id: provider.juneChain.id,
    },
  ]);
  const [sendMetadata, setSendMetadata] = useState<string>("");
  const [sended, setSended] = useState<boolean>(false);

  const handleCreateVesting = async () => {
    try {
      // check if all stakeholders have a stakeholder name
      if (stakeholders.some((stakeholder) => stakeholder.stakeholder === "")) {
        toast.error("Please enter a stakeholder name for each address");
        return;
      }

      // check if we have an amount for each stakeholder
      if (stakeholders.some((stakeholder) => stakeholder.amount === "")) {
        toast.error("Please enter an amount for each address");
        return;
      }

      // Convert BigInt values to strings
      const stakeholdersToSend = stakeholders.map((stakeholder) => ({
        amount: stakeholder.amount.toString(),
        blockchain_id: stakeholder.blockchain_id.toString(),
        address: stakeholder.address,
        stakeholder: stakeholder.stakeholder,
      }));

      const response = await appFetch(
        "/api/transactionsInternal/createTransactionsInternal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stakeholders: stakeholdersToSend,
            sendMetadata,
            networkId: provider.mcn.id.toString(),
          }),
        },
      );
      if (response) {
        toast.success("Vesting created");
        // set button to disabled
        setSended(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isValidAddress = (address: string, blockchain: Blockchain) => {
    try {
      const validAddress = new Address(address).encode(provider.mcn.hrp);
      if (!validAddress) {
        return false;
      }
      if (blockchain.id === provider.jvmChain.id) {
        return provider.mcn.primary.jvm.validateAddress(address);
      } else if (blockchain.id === provider.platformChain.id) {
        return provider.mcn.primary.platform.validateAddress(address);
      } else {
        return provider.mcn.primary.june.validateAddress(address);
      }
    } catch {
      return false;
    }
  };

  useEffect(() => {
    setSended(false);
  }, [stakeholders]);

  const handleReset = () => {
    setStakeholders([
      {
        address: "",
        stakeholder: "",
        amount: "",
        blockchain: provider.juneChain,
        blockchain_id: provider.juneChain.id,
      },
    ]);
    setSendMetadata("");
    setSended(false);
  };

  const getStakeholderName = async (address: string) => {
    if (address === "") {
      return "";
    }
    const response = await appFetch(
      `/api/transactionsInternal/getAddressesInternal?address=${address}`,
    );
    if (response.length === 0) {
      return "";
    }
    return response[0].metadata;
  };

  const handleAddStakeholder = async (value: string, index: number) => {
    const newStakeholders = [...stakeholders];

    if (!isValidAddress(value, newStakeholders[index].blockchain)) {
      newStakeholders[index].address = value;
      setStakeholders(newStakeholders);
    } else {
      newStakeholders[index].address = value;

      // get stakeholder name
      const stakeholderName = await getStakeholderName(value);
      if (stakeholderName) {
        newStakeholders[index].stakeholder = stakeholderName;
      } else {
        newStakeholders[index].stakeholder = "";
      }
      setStakeholders(newStakeholders);
    }
  };

  const handleDeleteStakeholder = (index: number) => {
    const newStakeholders = [...stakeholders];
    newStakeholders.splice(index, 1);
    setStakeholders(newStakeholders);

    // remove from filtered
    const newFiltered = [...filteredTransactions];
    // find the index of the transaction to remove
    const indexToRemove = newFiltered.findIndex(
      (transaction) => transaction.address === stakeholders[index].address,
    );
    newFiltered.splice(indexToRemove, 1);
    setFilteredTransactions(newFiltered);
  };

  const handleConvertAddress = async (stakeholder: Stakeholder) => {
    // update the selected stakholder address
    const newStakeholders = [...stakeholders];
    if (stakeholder.blockchain.id === provider.juneChain.id) {
      return;
    }
    const validAddress = new Address(stakeholder.address).encode(
      provider.mcn.hrp,
    );
    if (!validAddress) {
      return;
    }
    if (stakeholder.blockchain.id === provider.jvmChain.id) {
      newStakeholders[newStakeholders.indexOf(stakeholder)].address =
        "JVM-" + validAddress;
    } else if (stakeholder.blockchain.id === provider.platformChain.id) {
      newStakeholders[newStakeholders.indexOf(stakeholder)].address =
        "P-" + validAddress;
    }

    const stakeholderName = await getStakeholderName(stakeholder.address);
    if (stakeholderName) {
      newStakeholders[newStakeholders.indexOf(stakeholder)].stakeholder =
        stakeholderName;
    }

    setStakeholders(newStakeholders);
  };

  const getAddressPlaceholder = (blockchain: Blockchain) => {
    if (blockchain.id === provider.jvmChain.id) {
      return "JVM-...";
    } else if (blockchain.id === provider.platformChain.id) {
      return "P-...";
    } else {
      return "0x...";
    }
  };
  return (
    <div className="createTransaction">
      <div className="addressTitle">
        <span>Receiver addresses</span>
      </div>

      {stakeholders.map((address, index) => (
        <div
          className="recipientRow"
          key={address.address + "txforaddress" + index}
        >
          <StyledInputAddress
            value={address.address}
            setValue={(value) => handleAddStakeholder(value, index)}
            className="receiverAddress"
            placeholder={getAddressPlaceholder(address.blockchain)}
            isValid={isValidAddress(address.address, address.blockchain)}
          />
          <Button
            onClick={() => handleConvertAddress(address)}
            style="square"
            icon={<FaSort />}
          />
          <StyledInputNumber
            value={address.amount}
            setValue={(value) => {
              if (value === null) return;
              const newAddresses = [...stakeholders];
              newAddresses[index].amount = value;
              setStakeholders(newAddresses);
            }}
            label=""
          />
          <StyledInputText
            value={address.stakeholder}
            setValue={(value) => {
              const newAddresses = [...stakeholders];
              newAddresses[index].stakeholder = value;
              setStakeholders(newAddresses);
            }}
            label=""
            placeholder="Stakeholder"
          />

          <StyledDropdown
            value={address.blockchain}
            setValue={async (value) => {
              if (value) {
                const newAddresses = [...stakeholders];
                newAddresses[index].blockchain = value;
                newAddresses[index].blockchain_id = value.id;
                // check for the stakeholder name
                const stakeholderName = await getStakeholderName(
                  address.address,
                );
                if (stakeholderName) {
                  newAddresses[index].stakeholder = stakeholderName;
                }
                setStakeholders(newAddresses);
              }
            }}
            itemList={[
              provider.juneChain,
              provider.jvmChain,
              provider.platformChain,
            ]}
            itemLabelKey="name"
            itemIdKey={"id"}
          />

          <Button
            className="removeButton"
            onClick={() => handleDeleteStakeholder(index)}
            icon={<GrClose />}
            style="square"
            disabled={stakeholders.length === 1}
          />
        </div>
      ))}
      <Button
        icon={<GrAdd />}
        style="square"
        className="addButton"
        onClick={() =>
          setStakeholders([
            ...stakeholders,
            {
              address: "",
              stakeholder: "",
              amount: "",
              blockchain: provider.juneChain,
              blockchain_id: provider.juneChain.id,
            },
          ])
        }
      />

      <StyledInputText
        value={sendMetadata}
        setValue={setSendMetadata}
        label="Send Metadata"
      />

      <div className="buttonContainer">
        <Button
          onClick={() => handleCreateVesting()}
          disabled={!stakeholders || !sendMetadata || sended}
        >
          Create vesting
        </Button>
        <Button onClick={() => handleReset()} style="square">
          <FaRedo />
        </Button>
      </div>
    </div>
  );
};

export default CreateTransaction;
