import "./DynamicTable.scss";

import React, { useEffect, useMemo, useState } from "react";

import Button from "../Button/Button";
import { breakpoints } from "../config/Breakpoints";
import useWindowWidth from "../hooks/useWindowWidth";
import PaginationControls from "./PaginationControls/PaginationControls";
import RowAmount from "./RowAmount/RowAmount";
import Sort from "./sort.svg?react";

export interface Column {
  title: string | JSX.Element;
  dataIndex: string;
  colunmWidth?: string;
  sortable?: boolean; // Indiquate if the column is sortable
  onSort?: () => void; // Fonction to call when the column is sorted
  sortableClass?: string; // Class to apply to the button when the column is sorted
  render?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    record: any,
    index: number,
  ) => JSX.Element | string | number;
}

interface DynamicTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: Column[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (record: any) => void;
  rowHeight?: number;
  maxRow?: number;
  mobile?: boolean;
  mobileBreakpoint?: number;
  className?: string;
  pagined?: boolean;
}

const DynamicTable: React.FC<DynamicTableProps> = React.memo(
  ({
    data,
    columns,
    onClick,
    rowHeight = 55,
    maxRow,
    mobile,
    mobileBreakpoint = breakpoints["breakpoint-md-2"],
    className,
    pagined = false,
  }) => {
    const { windowWidth } = useWindowWidth();
    const [sortState, setSortState] = useState<{
      column: string;
      direction: "asc" | "desc";
    } | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState(data);
    const [maxRows, setMaxRows] = useState(maxRow);

    useEffect(() => {
      setMaxRows(maxRow);
    }, [maxRow]);

    useEffect(() => {
      setPaginatedData(paginatedData);
    }, [paginatedData]);

    useEffect(() => {
      if (!maxRows || !pagined) {
        setPaginatedData(data);
        return;
      }
      setPaginatedData(
        data.slice((currentPage - 1) * maxRows, currentPage * maxRows),
      );
    }, [data, currentPage, maxRows]);

    const handleSort = (col: Column) => {
      if (col.sortable) {
        const direction = sortState?.direction === "asc" ? "desc" : "asc";
        setSortState({ column: col.dataIndex, direction });
        if (col.onSort) {
          col.onSort();
        }
      }
    };

    const getSortClass = (col: Column) => {
      if (sortState && sortState.column === col.dataIndex) {
        return `active`;
      }
      return "";
    };

    const gridTemplateColumns = useMemo(() => {
      return columns.map((col) => col.colunmWidth || "1fr").join(" ");
    }, [columns]);

    const tableBodyStyle: React.CSSProperties = useMemo(() => {
      if (pagined) {
        return {};
      }
      return maxRows
        ? {
            maxHeight: `${maxRows * rowHeight - 2}px`,
            overflowY: "auto",
          }
        : {};
    }, [maxRows, rowHeight]);

    if (mobile && mobileBreakpoint >= windowWidth) {
      return (
        <div className="mobileTable">
          {data.map((record, rowIndex) => (
            <div
              key={rowIndex}
              className="card"
              style={{ width: "100%" }}
              onClick={() => (onClick ? onClick(record) : undefined)}
            >
              {columns.map((col, colIndex) => (
                <div key={colIndex} className="card-content">
                  {col.title !== "" && (
                    <span className="title">
                      <strong>{col.title}:</strong>{" "}
                    </span>
                  )}
                  <span className="content">
                    {col.render
                      ? col.render(record[col.dataIndex], record, rowIndex)
                      : record[col.dataIndex]}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className={`dynamicTable ${className ?? ""}`}>
          <header
            className="tableHeader"
            style={{ gridTemplateColumns: gridTemplateColumns }}
          >
            {columns.map((col, index) => (
              <span
                key={index}
                className={`col ${col.sortable ? "sortable" : ""}`}
                onClick={() => col.sortable && handleSort(col)}
              >
                {col.title}
                {col.sortable && (
                  <Button
                    icon={<Sort />}
                    style="transparent"
                    // give asc or desc class to the button
                    className={`${col.sortableClass || ""} ${getSortClass(col)}`}
                  />
                )}
              </span>
            ))}
          </header>
          <section className="tableBody" style={tableBodyStyle}>
            {paginatedData.map((record, rowIndex) => (
              <div
                className="tableRow"
                key={rowIndex}
                onClick={() => (onClick ? onClick(record) : undefined)}
                style={{
                  gridTemplateColumns: gridTemplateColumns,
                  height: `${rowHeight}px`,
                  cursor: onClick ? "pointer" : "default",
                  paddingRight: pagined ? "0px" : "10px",
                }}
              >
                {columns.map((col, colIndex) => (
                  <span key={colIndex} className="col">
                    {col.render
                      ? col.render(record[col.dataIndex], record, rowIndex)
                      : record[col.dataIndex]}
                  </span>
                ))}
              </div>
            ))}
          </section>
          {pagined && maxRows && (
            <div className="tableControllers">
              <RowAmount maxRows={maxRows} setMaxRows={setMaxRows} />
              <PaginationControls
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                totalCount={data.length}
                pageSize={maxRows}
                siblingCount={2}
              />
            </div>
          )}
        </div>
      );
    }
  },
);

DynamicTable.displayName = "DynamicTable";

export default DynamicTable;
