import {
  ButtonSwitcher,
  Container,
  ContainerContent,
} from "../../mcn-react-ui";
import GetNetworkData from "../../mcn-scripts/juneojs/components/GetNetworkData";
import useNetworkStore, { networkList } from "../../stores/NetworkStore";

const NetworkData = () => {
  const { network, setNetwork } = useNetworkStore();

  return (
    <Container>
      <ContainerContent className="trackValidatorPageDiv">
        <ButtonSwitcher
          datas={networkList}
          value={network}
          setValue={setNetwork}
        />

        <GetNetworkData network={network.id} />
      </ContainerContent>
    </Container>
  );
};

export default NetworkData;
