import "./ValidatorCard.scss";

import React from "react";

export type ValidatorInfo = {
  nodeID: string;
  connected: boolean;
  uptime: string;
  startTime: Date;
  endTime: Date;
  elapsedTime: string;
  remainingTime: string;
  stake: string;
  potentialReward: string;
};

interface ValidatorCardProps {
  validator: ValidatorInfo;
  index?: number;
}

const ValidatorCard: React.FC<ValidatorCardProps> = ({ validator, index }) => {
  return (
    <div className="validatorCard">
      <div>
        <label>nodeID: </label>
        <span>{validator.nodeID}</span>
      </div>
      <div>
        <label>connected: </label>
        <span>{validator.connected.toString()}</span>
      </div>
      <div>
        <label>uptime: </label>
        <span>{validator.uptime}</span>
      </div>
      <div>
        <label>startTime: </label>
        <span>{validator.startTime.toLocaleDateString()}</span>
      </div>
      <div>
        <label>endTime: </label>
        <span>{validator.endTime.toLocaleDateString()}</span>
      </div>
      <div>
        <label>elapsedTime: </label>
        <span>{validator.elapsedTime}</span>
      </div>
      <div>
        <label>remainingTime: </label>
        <span>{validator.remainingTime}</span>
      </div>
      <div>
        <label>stake: </label>
        <span>{validator.stake}</span>
      </div>
      <div>
        <label>potentialReward: </label>
        <span>{validator.potentialReward}</span>
      </div>
      {index && <span className="index">{index + 1}</span>}
    </div>
  );
};

export default ValidatorCard;
