import "./JuneoValidators.scss";

import {
  ButtonSwitcher,
  Container,
  ContainerContent,
} from "../../mcn-react-ui";
import FetchJuneoValidators from "../../mcn-scripts/juneojs/components/FetchJuneoValidators";
import useNetworkStore, { networkList } from "../../stores/NetworkStore";

const JuneoValidators = () => {
  const { network, setNetwork } = useNetworkStore();

  return (
    <Container>
      <ContainerContent className="latestValidatorsPageDiv">
        <ButtonSwitcher
          datas={networkList}
          value={network}
          setValue={setNetwork}
        />

        <FetchJuneoValidators network={network.id} />
      </ContainerContent>
    </Container>
  );
};

export default JuneoValidators;
