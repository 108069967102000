import { useEffect, useState } from 'react'

import getNetworkData, { NetworkData } from '../scripts/getNetworkData'
import UptimeCart from './UptimeCart/UptimeCart'

interface GetNetworkDataProps {
  network: string
}
const GetNetworkData = ({ network }: GetNetworkDataProps) => {
  const [networkData, setNetworkData] = useState<NetworkData>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setNetworkData(await getNetworkData(network))
      } catch (error) {
        console.error('Error fetching network data:', error)
      }
    }

    fetchData()
  }, [network])

  useEffect(() => {
    console.log('networkData:', networkData)
  }, [networkData])

  return (
    <>
      {networkData ? (
        <div>
          <p>{networkData.peersOnline}</p>
          {Array.from(networkData.peersVersions).map(([version, count]) => (
            <p key={version}>
              {version}: {count}
            </p>
          ))}
          <p>{networkData.validatorsOnline}</p>
          <p>{networkData.validUptimes}</p>
          <p>{networkData.meanUptime}</p>
          <p>{networkData.medianUptime}</p>
          <p>{networkData.totalStake}</p>
          <p>{networkData.connectedStake}</p>
          <p>{networkData.rewardingStake}</p>
          <p>{networkData.pendingRewards}</p>

          <UptimeCart data={networkData.validatorsUptimes} />
        </div>
      ) : (
        <p>Loading network data...</p>
      )}
    </>
  )
}

export default GetNetworkData
