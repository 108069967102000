import "./FormatNumber.scss";

import { ethers } from "ethers";
import React from "react";

interface FormatNumberProps {
  value: string | number | bigint;
  decimals?: number;
  toFixed?: number;
  alias?: string;
  colored?: boolean;
  className?: string;
}

const FormatNumber: React.FC<FormatNumberProps> = ({
  value,
  decimals, // TODO : change with assetValue .getReadableValue()
  toFixed = 2,
  alias,
  colored,
  className,
}) => {
  function formatNumber(num: number): string {
    if (Number.isNaN(num)) {
      return "0";
    }
    if (decimals) {
      return ethers.formatUnits(value, decimals);
    }
    let formattedNum = num.toFixed(toFixed).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    formattedNum = formattedNum.replace(/\.?0+$/, "");
    if (formattedNum.length >= 12) {
      formattedNum = formattedNum.slice(0, 12);
    }
    return formattedNum;
  }

  const formattedValue = formatNumber(Number(value));
  const isNegative = Number(value) < 0;
  const color = colored ? (isNegative ? "#F25050" : "#38C769") : "inherit";

  return (
    <span className={`formatNumberWrapper ${className ? className : ""}`}>
      <span className={`formatNumber`} style={{ color }} data-colored={colored}>
        {formattedValue}
      </span>
      {alias && (
        <span
          className={`formatNumberAlias`}
          style={{ color }}
          data-colored={colored}
        >
          &nbsp;
          {alias === "P" || alias === "JVM" ? "JUNE" : alias}
        </span>
      )}
    </span>
  );
};

export default FormatNumber;
