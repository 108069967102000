import { ValidatorData } from 'juneojs'
import { useEffect, useState } from 'react'

import fetchJuneoValidators from '../scripts/fetchJuneoValidators'
import ErrorComponent from './ErrorComponent'
import ValidatorCard, { ValidatorInfo } from './ValidatorCard/ValidatorCard'

interface FetchJuneoValidatorsProps {
  network: string
}
const FetchJuneoValidators = ({ network }: FetchJuneoValidatorsProps) => {
  const [latestValidators, setJuneoValidators] = useState<ValidatorInfo[]>()
  const [latestValidatorsError, setJuneoValidatorsError] = useState<
    string | undefined
  >()

  const [disconnected, setDisconnected] = useState<Map<string, ValidatorData>>()
  const [found, setFound] = useState<Map<string, ValidatorData>>()
  const [validators, setValidators] = useState<string[]>()
  const [formattedJuneoStake, setFormattedJuneoStake] = useState<string>()

  useEffect(() => {
    const getJuneoValidators = async () => {
      try {
        const datas = await fetchJuneoValidators(network)
        setJuneoValidators(datas.juneoValidators)
        setDisconnected(datas.disconnected)
        setFound(datas.found)
        setValidators(datas.validators)
        setFormattedJuneoStake(datas.formattedJuneoStake)
        setJuneoValidatorsError(undefined)
      } catch (error) {
        console.error('Error fetching network data:', error)
        if (error instanceof Error) {
          setJuneoValidatorsError(error.message)
        }
        setJuneoValidators(undefined)
        setDisconnected(undefined)
        setFound(undefined)
        setValidators(undefined)
        setFormattedJuneoStake(undefined)
      }
    }
    getJuneoValidators()
  }, [network])

  return (
    <>
      {disconnected &&
        (disconnected.size < 1 ? (
          <div className="dataDisplay">
            <div className="dataField">
              <label>
                All validators are connected on the {network} network
              </label>
            </div>
          </div>
        ) : (
          <div className="dataDisplay">
            <div className="dataField">
              <label>
                List of disconnected validators on the {network} network:
              </label>
              {Array.from(disconnected.entries()).map(([nodeId]) => (
                <span key={nodeId} className="nodeId">
                  {nodeId}
                </span>
              ))}
            </div>
          </div>
        ))}
      {found &&
        validators &&
        (found.size >= validators.length ? (
          <div className="dataDisplay">
            <div className="dataField">
              <label>No missing validators on the {network} network</label>
            </div>
          </div>
        ) : (
          <div className="dataDisplay">
            <div className="dataField">
              <label>
                List of missing validators on the {network} network:
              </label>
              {validators &&
                validators.map((nodeId) =>
                  !found.has(nodeId) ? (
                    <span key={nodeId} className="nodeId">
                      {nodeId}
                    </span>
                  ) : null,
                )}
            </div>
          </div>
        ))}
      {formattedJuneoStake && (
        <div className="dataDisplay">
          <div className="dataField">
            <label>Juneo Stake: </label>
            <span>{formattedJuneoStake}</span>
          </div>
        </div>
      )}
      <div className="latestValidatorsList">
        {latestValidators &&
          latestValidators.map((validator) => (
            <ValidatorCard key={validator.nodeID} validator={validator} />
          ))}
        {latestValidatorsError && (
          <ErrorComponent error={latestValidatorsError} />
        )}
      </div>
    </>
  )
}

export default FetchJuneoValidators
