import {
  GenesisNetwork,
  MainNetwork,
  MCNProvider,
  SocotraNetwork,
} from "juneojs";
import { Dispatch, SetStateAction } from "react";
import { create } from "zustand";

type NetworkType = {
  id: string;
  name: string;
};

interface NetworkState {
  network: NetworkType;
  setNetwork: Dispatch<SetStateAction<NetworkType>>;

  provider: MCNProvider;
  setProvider: (provider: MCNProvider) => void;
}

const useNetworkStore = create<NetworkState>((set) => ({
  network: { id: "Socotra", name: "Socotra" },
  provider: new MCNProvider(SocotraNetwork),

  setNetwork: (value: SetStateAction<NetworkType>) =>
    set((state) => {
      let newProvider;
      if (typeof value === "function") {
        newProvider = state.provider;
      } else {
        switch (value.id) {
          case "Genesis":
            newProvider = new MCNProvider(GenesisNetwork);
            break;
          case "Socotra":
            newProvider = new MCNProvider(SocotraNetwork);
            break;
          case "Mainnet":
            newProvider = new MCNProvider(MainNetwork);
            break;
          default:
            newProvider = state.provider;
        }
      }
      // Mise à jour du network et du provider
      return {
        network: typeof value === "function" ? value(state.network) : value,
        provider: newProvider,
      };
    }),

  setProvider: (provider: MCNProvider) =>
    set({
      provider,
    }),
}));

export const networkList = [
  { id: "Genesis", name: "Genesis" },
  { id: "Socotra", name: "Socotra" },
  { id: "Mainnet", name: "Mainnet" },
];

export default useNetworkStore;
