import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

interface UptimeCartProps {
  data: Map<number, number>
}

const UptimeCart: React.FC<UptimeCartProps> = ({ data }) => {
  // Convert the data map to an array of objects
  const dataArray = Array.from(data, ([key, value]) => ({
    uptime: key,
    count: value,
  }))

  return (
    <BarChart
      width={500}
      height={300}
      data={dataArray}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="uptime"
        label={{
          value: 'Uptime Percentage',
          position: 'insideBottom',
          offset: -5,
        }}
      />
      <YAxis
        label={{
          value: 'Number of Validators',
          angle: -90,
          position: 'insideLeft',
        }}
      />
      <Tooltip />
      <Legend
        verticalAlign="top"
        wrapperStyle={{ lineHeight: '40px' }}
        formatter={(value) =>
          value === 'count' ? 'Number of Validators by Uptime' : value
        }
      />
      <Bar dataKey="count" name="Validators" fill="#0c90ff" />
    </BarChart>
  )
}

export default UptimeCart
