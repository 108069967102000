import {
  GenesisNetwork,
  MainNetwork,
  MCNProvider,
  SocotraNetwork,
} from 'juneojs'

export function getNetworkValidators(provider: MCNProvider): string[] {
  let validators: string[] | undefined = undefined
  switch (provider.mcn.id) {
    case GenesisNetwork.id: {
      validators = [
        'NodeID-5SngxwT4WZV5xCkzYTGqUZWq5jybJmkSN',
        'NodeID-GX8JhkccGomrnTXdT3f2e6Tjq84SSv7pi',
      ]
      break
    }
    case SocotraNetwork.id: {
      validators = [
        'NodeID-GE8tPpvqUn5tzKPdZW4rea7z3bRjz9PBv',
        'NodeID-5rKuXJwdzQcCPupijwNWY1TmS7iThJCj7',
        'NodeID-NxMeHVQwrJBXUcHKX5jGwBgL4nUGjDbh7',
        'NodeID-AEBwXRFCzLqJfAhh4nP8YniYGcxZ98X86',
        'NodeID-2MQSS8m4XFZws2dFKtttAUVgAKZiu1Ad8',
        'NodeID-LMEszCjUqH1s2fixWNLHJcQoH6ev63A7e',
        'NodeID-FCducKRRaDKqe2LSWadHoT6cZQS8rctKG',
        'NodeID-gEgTTvf6z7aTC8qRdJNbLa3TRozyYrWg',
        'NodeID-PVN6S2RRKJXJQ5HTLJRK9EjRgtV686euN',
        'NodeID-Py6m8xe1S9azkF1SuXCcTrkz5mW9tCrQm',
        'NodeID-NYB3BSPus3mcvdCpse32dRwE5yXuK6p2j',
        'NodeID-BoqeDDwFgzcPSK52XRYhP3dsUB1rfjLzk',
        'NodeID-D8MDpRNa4NCoWLAKzm3HpNBQB8DSaw9Yo',
        'NodeID-23EWAPWJYHWKPywBgoyPfntdKgHBB5LSS',
        'NodeID-LQ22mQcXyoQJCQBxgtWKTvBVPGjKbuXQK',
        'NodeID-8cJknwug8CArUjumW7fr7PHvZW4UUuzcE',
        'NodeID-149NKQBxQDJdZ6TE51nxKqmDUZ353CfiR',
        'NodeID-K4knbfWNpxRYbrcgvtqe7aAkS1tcvDK8P',
        'NodeID-Bc4g1PqkXSpvfCkuoM6r8quhN7MWJx16q',
        'NodeID-PnSrjQGFaKdQSRcusrrHivgEE98J8Qn4y',
        'NodeID-ASRLACDFgJAD1cf6prvxBsi5mKzaBLR9a',
        'NodeID-4JfgcoMWBpxCQL5VmyQ1f6L36mUbLLBga',
        'NodeID-KiHrNQ7hSXxP4VMkw9pXwbmsu9SXRUrmu',
        'NodeID-Eghqh1yvnGCN3ZXHsrbhx9km1WPwLX3kS',
        'NodeID-BULFk1Xkx2pgq72qvaWqaWigAPZkDGKyu',
      ]
      break
    }
    case MainNetwork.id: {
      validators = [
        'NodeID-MjLFwqKKvxLbZtXZeZSRAcp3MoE1HVj7T',
        'NodeID-L8U5eeuaaaQxGdYsYF7RFmtMxxipmSAX6',
        'NodeID-62jqsLAFqHm2ZCvbisGh6ZqhVH5tHtsqD',
        'NodeID-9eD6PUkwSxSmmEzYSjL8JtBKo88wcb6Qn',
        'NodeID-DRUn7cpKYHs4mkafUbBRJD6AsDkTjSWSy',
        'NodeID-vyM9Y4LDj7P28VS8BDXohmycJaeRgmqC',
        'NodeID-12pkoNJu46ck6kLiMyaAdYtj7LJVSH9b',
        'NodeID-NM3ekr1zbivAga1vnBgnrWBNQQgadYwNH',
        'NodeID-KMmpkFPcQZ6pUtafYzU8wWZpSCXnxNcDG',
        'NodeID-GDmxZEDQSU3hAS9bSisLQkhBbDcuQBmU6',
        'NodeID-kvzG7HvbEKv84gDzNfbJx6bkm9V5xXGv',
        'NodeID-JLmB5sydBLRDfruR353wqqKLpK6nubJra',
        'NodeID-CGNUhWmVpBoJQSAmQASaUnWQLdZnnKgKC',
        'NodeID-3CPTc7tHoDBRbhsjxL2XqPoyj5SxAujqE',
        'NodeID-KqazoqbGKQ3CgyTXWWrqurpJucdYx8w9L',
        'NodeID-LHgARJ38DXwGKKuRNqk3a2NbcDnDtD4Pu',
        'NodeID-GWpQJbEgSKWRuePk1qwkV3VxD4YMQYjuQ',
        'NodeID-HDzEB9bG8NNvB5R8EuFdHycb72uX1eJut',
        'NodeID-GAhhU4xxuABqarp5jkCr2pDEFTDXiE337',
        'NodeID-Cg99vmSC1W6BrCDS5HjjkFKVfKU6m6MCP',
        'NodeID-FzSUK1X42fcP2RNGCYHptqcUf8hp3uq3L',
        'NodeID-HBpmWphmWNKRwoebovUSC2zmdonooiu7g',
        'NodeID-7M5c6dwnYrb8fpJUPjvNTd7doE3AiHaw5',
        'NodeID-6ShbRpgPC9L9ZXcpkEPNub3CN94JToYNB',
        'NodeID-9UVVbz4JaTZCtkx3Hi8LoaQr3GjePFwc3',
        'NodeID-BvME48TGi8NhdBroZa3tDKS1XQooxPeNp',
        'NodeID-ETuv6fW2XdbpBQiaoeDHzxU98RLJq5mp6',
        'NodeID-2DF8Ec2xshVtTKqNwioVQFdoK8828PLaa',
        'NodeID-DMEcxfDsEKMWXVC4U3d4dc3fxtz6DWbSE',
        'NodeID-ND5ED9n9LSU51wQ7X1VB2knBmLXduMpuS',
        'NodeID-MbSWHPo91BigBrQn9wCjysC2fYRXSaGZM',
        'NodeID-5mkQyhVMH3RQX3D1LW47jJBvQPq5Ahd6e',
        'NodeID-8jE3dXQc6X6gaBJx8ZAALXCXfpWnBaFp9',
        'NodeID-6FxTUozXLMRGsh9FXqm4HukAGtw2HCy5h',
        'NodeID-KwMeGjqjqJzemhyDqP71YoAFAi8Rgpvi3',
        'NodeID-r1xVeDC5Qyphy2HrPsnjSaWqMUqxEB2Z',
        'NodeID-Lj8NHft2iERvtR2zre8RRYrM3aXH6aM3h',
        'NodeID-K3ik2a16MmndJWzJ6CV6X9AGxWCHUALmR',
        'NodeID-HhBonQyGN95dwazebEPuaKXDCREbuCRak',
        'NodeID-PwDVLZmJvcrnHpF9m85BwmXALXp7t3kem',
        'NodeID-PvaCr7i6MntH2zXrir9p1wRadDoi2J9cH',
        'NodeID-CAkhUJ8Nyypjp7h2hkGJqZkoXuD7jntX6',
        'NodeID-Bnwo6KG9KpbXs2TZM339dAcPUHTSoUsi8',
        'NodeID-LHMdSpmScP7eKfgd9b2VV8B4BaLsFhz5b',
        'NodeID-4XbGZaZCuaL3EWUAvD4C9VpNvdDChDWdU',
        'NodeID-3NyXtvhSRB1FarrA9F4mbyE8x93LY5fFz',
        'NodeID-FUWQzMjzwFycwKyUWuPxE4LCXJNanJsRb',
        'NodeID-A1QiSJvTe53a6hhDcxVVPnSxU89TNQhzj',
        'NodeID-D8WnRX6aEy3jX6ZGxWHS69a6D7SHe4P6m',
        'NodeID-4JDD7dLQ9zebnGUaiKW3sQXatFi3smgdX',
        'NodeID-8RiEYZqqdEZea2tA2uQuv9MQEn1f53Ywo',
        'NodeID-BXQuxUqvwkoC1taawv6HAJFUefnYeiisA',
        'NodeID-KprgqmBVxw6oGxqKXhBEhEWDByGSSLkaW',
        'NodeID-PoRunY5Pmb3RzSahG2Fag3x9g4jaZHYD3',
        'NodeID-Eh9MBoXkv2uCkfbmrzN6Jq63qS9QghJkE',
        'NodeID-5X3FGpzSnuz7Rz6q8chHKbWA2rimFeRfq',
        'NodeID-rPSF1KZD22pJYNSGTEJZ7UGPRck7Muhk',
        'NodeID-Mn8z5sBAvz2Uw7nLNc9jjUHkFDZs6a1ZX',
        'NodeID-GJ7GSiHp61hFMhxR7TJgxTwU5pmcbUpdT',
        'NodeID-MRmoPuPqd394HTUFwEzz6efWkrjgn1KS3',
        'NodeID-4CRVwim3Mb72R445N1UJxgbyUknvz4D88',
        'NodeID-HdipLQngRRoguXnqxaMQmhvuEipxKnVpi',
        'NodeID-EwFfnGMGHV8ZyoPHXGsywASg9AFZhmWSq',
        'NodeID-7aitPUQ4GF3xUXNbmgTpwhFreS1C9iAE7',
        'NodeID-9XNNxQ1Ro8C7Cyah8Zd1XaVCdH8S6ZUHj',
        'NodeID-77wSiaMxoVzmeZ1DcFACiNz5xFdpccjDL',
        'NodeID-NfWLwJqpaDjK6LQGwQy5FP4ebyJpZ14Tw',
        'NodeID-Lv9TseJoYDiSdRQ3Zgj6SHrA6Z1yy5mv7',
        'NodeID-MBTgWeESHLp3XLqi4XHXudKHtxWhJPXcU',
        'NodeID-CxWDFj4AbMpTUiYUSSSYH3zGsn7Ds4t89',
        'NodeID-F8qMmPKzaK8cdVTQEFqfyJPWeo9gPaSbE',
        'NodeID-2BLWMGM1pKLyPQp1aVt6npJtYmM5eu7Kd',
        'NodeID-FNhVxFpbZgZ8Csco1suDf1MShk5jNbFKh',
        'NodeID-EPR6NbPcVPq1DJb4nas4tuSriutZLENsG',
        'NodeID-K8tgbZLuvum4gW4WrDPYH86t6QV7EBZBR',
        'NodeID-2sDnRPwxwYfa7gHz168TsCP4vcfdy3RD7',
        'NodeID-MznUDCbtm1RAX8X8DYyhCcYqYWxdWEvXP',
        'NodeID-8SEyoJfU4zapvWn76wdxVFkDNsRaE4JMk',
        'NodeID-KBiEMsQeJb5uvixhj8JCkUsScwN3876vZ',
        'NodeID-MUcoCHrrkwctKJDam6MoQZSQwoZqXUnNv',
        'NodeID-HeL3qsf47oU5Qfm8qvSNsSfFmFMtK3EV1',
        'NodeID-3G958JfgggwcNmBtMRNiu1GHHu7oahqdp',
        'NodeID-MD9HDBi1vQmv2HeXbSsCzUDwrzcR5cqME',
        'NodeID-D51ZYBXMiwsEkGNKmoDEmYsbEg4idAvtX',
        'NodeID-Ghb8evEUqohUdRuMuephVpE3PR6D7kbjY',
        'NodeID-L4smYWN2ugRh1TwAvVTMSMgoYQiNRidHk',
        'NodeID-DNKftUh1LC9Fwy5myo4hbm3Gf8yX3BgrH',
        'NodeID-K7uNGBkXXYbw63s61fnhM22PhFEouHrD8',
        'NodeID-MsQJTWCYuJxZQWc1prjdFsyDdEfKmgBAX',
        'NodeID-6QtufPXEum8n7vxzSx8nHbFkLiRBCTnyy',
        'NodeID-Dx4gDz2SWhbp8j9agSzcydhmjrN5LFSSr',
        'NodeID-HQtBX3g5GFYC8urrXwvsijbuAwNGp1Xhs',
        'NodeID-KA1ctcc7vQGNAuEGBgVQqMWjWnLda55EA',
        'NodeID-4wvzWZFMuk4KCiLA4D6GfhKz53tcvqmct',
        'NodeID-2sfFfsRY9XkjP5tdwfp1NVtLMQ6QfYtRV',
        'NodeID-45wZb7LE2gKCsJiBMBu1fgnfkg9uRjKN2',
        'NodeID-8Rds8zgdmuHTmP6B1wMy3QZoVmWavf9uP',
        'NodeID-FK7S1syTv5RgvVZXhAtqhDZrLRKhaZt2X',
        'NodeID-PzvumBCAgaYsL4GWCyoXkH21HAdu19M81',
        'NodeID-A6ocyF4MeZ4of2Ay4AgVUimkqrRLj7xhV',
        'NodeID-KcmuRPVS9ML7GJqLzSjKQV1h2bQjKL9zB',
        'NodeID-KxCL8x88J5Ao1eMPHPY2Li5Ci87Bij5tj',
      ]
      break
    }
  }
  if (typeof validators === 'undefined') {
    throw new Error(
      'unexpected data missing validators list please update script',
    )
  }
  return validators
}
